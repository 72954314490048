import React, { useEffect, useRef, useState } from 'react';
import { Icon } from "@iconify/react/dist/iconify.js";
import WTPSideBar from './WTPSideBar';
import "../../css/wtpstyle.css";
import { FaCheckCircle, FaFileAlt } from 'react-icons/fa';
import { FaUser, FaPaperPlane } from 'react-icons/fa';
import { FaExclamationTriangle } from 'react-icons/fa';
import ModelUsers from './ModelUsers';
import { useNavigate } from 'react-router-dom';
import Backend_Url from '../../config/config';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';


const WTPCampaigns = () => {

  const renderStatusIcon = (status) => {
    if (status === 'Delivered') {
      return <FaCheckCircle style={{ color: 'green', marginRight: '5px' }} />;
    } else if (status === 'Draft') {
      return <FaFileAlt style={{ color: 'orange', marginRight: '5px' }} />;
    }
    return null;
  };

  const renderContactsIcon = () => {
    return <FaUser style={{ color: '#acacae', marginRight: '5px' }} />;
  };

  const renderSentIcon = () => {
    return <FaPaperPlane style={{ color: 'gray', marginRight: '5px' }} />;
  };

  const renderFailedIcon = () => {
    return <FaExclamationTriangle style={{ color: '#ff0000de', marginRight: '5px' }} />;
  };


  /******************************************************** */
  const [apiData, setApiData] = useState();
  const [cookies] = useCookies(["token"]);


  const fetchData = async (type) => {
    // debugger
    const token = cookies.token;

    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      const response = await axios.get(`${Backend_Url}/whatsapp/v1/campaignorquery/getbytype/${type}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response) {
        if (type === 1) {
          console.log("Type 1 Data", response.data.records);
          setApiData(response.data.records)
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData(1)
  }, []);
  useEffect(() => {
    console.log(apiData);
  }, [apiData]);
  const [showMenu, setShowMenu] = useState(null); // State to manage the visibility of the menu
  const menuRefAction = useRef(null); // Ref for the dropdown menu

  const handleIconClick = (id) => {
    setShowMenu((prev) => (prev === id ? null : id)); // Toggle menu visibility
  };

  const handleEdit = (id) => {
    // Implement edit functionality
    console.log(`Edit campaign ${id}`);
    setShowMenu(null); // Close menu after action
  };

  const handleDelete = (id) => {
    // Implement delete functionality
    deleteCampaign(id)
    console.log(`Delete campaign ${id}`);
    setShowMenu(null); // Close menu after action
  };

  // const handleClickOutsideAction = (event) => {
  //   if (menuRefAction.current && !menuRefAction.current.contains(event.target)) {
  //     setShowMenu(null);
  //   }
  // };

  // useEffect(() => {
  //   // Add event listener for clicks outside of the menu
  //   document.addEventListener('mousedown', handleClickOutsideAction);

  //   // Cleanup the event listener
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutsideAction);
  //   };
  // }, []);


  const deleteCampaign = async (id) => {
    const token = cookies.token;
    if (!token) {
      console.error('No token found');
      return;
    }
    try {
      const response = await axios.delete(`${Backend_Url}/whatsapp/v1/campaignorquery/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        fetchData(1)
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: 'The campaign was deleted successfully.',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There was an issue deleting the campaign. Please try again.',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error("Unable to delete campaign:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Unable to delete the campaign. Please check your network connection or contact support.',
        confirmButtonText: 'OK',
      });
    }
  };
  /********************************************** */

  const [showMenuUser, setShowMenuUser] = useState(false); // State to manage dropdown visibility
  const menuRef = useRef(null); // Ref for the dropdown menu

  // Toggle dropdown visibility
  const handleButtonClick = () => {
    setShowMenuUser(prev => !prev);
  };

  // Handle clicks outside of the menu to close it
  // const handleClickOutside = (event) => {
  //   if (menuRef.current && !menuRef.current.contains(event.target)) {
  //     setShowMenuUser(false);
  //   }
  // };

  // useEffect(() => {
  //   // Add event listener for clicks outside of the menu
  //   document.addEventListener('mousedown', handleClickOutside);

  //   // Cleanup the event listener
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);


  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/createcampaigns');
  };



  // const data = [
  //   { id: 1, name: 'Campaign A', date: '01-07-2024', time: '10:30', status: 'Delivered', contacts: 150, sent: 120, failed: 5 },
  //   { id: 2, name: 'Campaign B', date: '15-06-2024', time: '09:00', status: 'Draft', contacts: 200, sent: 180, failed: 10 },
  //   { id: 3, name: 'Campaign C', date: '10-07-2024', time: '11:45', status: 'Delivered', contacts: 250, sent: 230, failed: 8 }
  // ];

  return (
    <div className="Flex-Head-Right-Compaigns">
      <div>
        <WTPSideBar />
      </div>
      <div className="Right-Campaigns-Body">
        <div className="Campaigns_header">
          <div className="Campaigns_header">
            <h3>Campaigns</h3>
          </div>
          <div className="Campaigns_headerRight">
            <button className="Campaigns_headerButton" onClick={handleButtonClick}>
              <Icon icon="pepicons-pop:hash-circle-filled" />
              {showMenuUser && (
                <div ref={menuRef}>
                  <ModelUsers />
                </div>
              )}
            </button>
          </div>
        </div>
        <div className="Campaigns_body_Bg">
          <div className="Create-Compaigns-container">
            <button className="view-all-button" onClick={handleClick}>
              + Create New Compaigns
            </button>
          </div>
          <div className="Campaigns-form-content">
            <div>
              <table className="Table-Body-content">
                <thead className="Table-Campaigns-color">
                  <tr className="Table-Bg-color">
                    <th className="th-name-camp">Campaigns name</th>
                    <th className="th-name-camp">Created date</th>
                    <th className="th-name-camp">Status</th>
                    <th className="th-name-camp">Contacts</th>
                    <th className="th-name-camp">Sent</th>
                    <th className="th-name-camp">Failed</th>
                    <th className="th-name-camp"></th>
                  </tr>
                </thead>
                <tbody>
                  {apiData && apiData.map(item => (
                    <tr key={item.id} className="Table-Bg-color">
                      <td className="td-name-campaigns">{item.campaignOwner}</td>
                      <td className="td-name-campaigns">{item.createdAt} </td>
                      <td className="td-name-campaigns">
                        {renderStatusIcon(item.status)} {item.status}
                      </td>
                      <td className="td-name-campaigns">
                        {renderContactsIcon()} {item.contacts}
                      </td>
                      <td className="td-name-campaigns">
                        {renderSentIcon()} {item.sent}
                      </td>
                      <td className="td-name-campaigns">
                        {renderFailedIcon()} {item.failed} Failed
                      </td>
                      <td className="td-name-campaigns">
                        <div className="action-icon-container" ref={menuRefAction}>
                          <Icon
                            icon="bi:three-dots-vertical"
                            onClick={() => handleIconClick(item.id)}
                            style={{ cursor: 'pointer' }}
                          />
                          {showMenu === item.id && (
                            <div className="dropdown-menu-action">
                              <div>
                                <button onClick={() => handleEdit(item.id)}>Edit Campaign</button>
                              </div>
                              <div>
                                <button onClick={() => handleDelete(item.id)}>Delete Campaign</button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="view-all-container">
            <button className="view-all-button">
              View All
            </button>
          </div>
        </div>


      </div>
    </div>
  )
}

export default WTPCampaigns
