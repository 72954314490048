import React from "react";
import "./Sidebar.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="sidebar-container">
      <h1 className="text-center">Techcherry Admin Panel</h1>
      <div className="list">
        <ul>
          <li>
            <NavLink
              to="/Dashboard"
              style={({ isActive }) => ({
                textDecoration: "none",
                color: isActive ? "grey" : "inherit",
                fontWeight: isActive ? "bold" : "normal",
              })}
            >
              <div className="menu">
                <div>
                  <Icon
                    className="icons"
                    icon="clarity:dashboard-solid"
                    width="20"
                    height="20"
                    style={{ color: "black" }}
                  />
                </div>
                <div className="Side-Title-Name">Dashboard</div>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/GeneralSetting"
              style={({ isActive }) => ({
                textDecoration: "none",
                color: isActive ? "grey" : "inherit",
                fontWeight: isActive ? "bold" : "normal",
              })}
            >
              <div className="menu">
                <div>
                  <Icon
                    className="icons"
                    icon="icon-park-solid:setting"
                    width="20"
                    height="20"
                    style={{ color: "black" }}
                  />
                </div>
                <div className="Side-Title-Name">General Setting</div>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/slider"
              style={({ isActive }) => ({
                textDecoration: "none",
                color: isActive ? "grey" : "inherit",
                fontWeight: isActive ? "bold" : "normal",
              })}
            >
              <div className="menu">
                <div>
                  <Icon
                    className="icons"
                    icon="bx:slider"
                    width="20"
                    height="20"
                    style={{ color: "black" }}
                  />
                </div>
                <div className="Side-Title-Name">Slider</div>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/"
              style={({ isActive }) => ({
                textDecoration: "none",
                color: isActive ? "grey" : "inherit",
                fontWeight: isActive ? "bold" : "normal",
              })}
            >
              <div className="menu">
                <div>
                  <Icon
                    className="icons"
                    icon="solar:gallery-bold"
                    width="20"
                    height="20"
                    style={{ color: "black" }}
                  />
                </div>
                <div className="Side-Title-Name">Gallery</div>
              </div>
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/settings"
              style={({ isActive }) => ({
                textDecoration: "none",
                color: isActive ? "grey" : "inherit",
                fontWeight: isActive ? "bold" : "normal",
              })}
            >
              <div className="menu">
                <div>
                  <Icon
                    className="icons"
                    icon="icon-park-solid:setting"
                    width="24"
                    height="24"
                    style={{ color: "black" }}
                  />
                </div>
                <div>General Settings</div>
              </div>
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/newsnotice"
              style={({ isActive }) => ({
                textDecoration: "none",
                color: isActive ? "grey" : "inherit",
                fontWeight: isActive ? "bold" : "normal",
              })}
            >
              <div className="menu">
                <div>
                  <Icon
                    icon="mdi:bell"
                    width="20"
                    height="20"
                    style={{ color: "black" }}
                  />
                </div>
                <div className="Side-Title-Name">News & Notice</div>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/flashnews"
              style={({ isActive }) => ({
                textDecoration: "none",
                color: isActive ? "grey" : "inherit",
                fontWeight: isActive ? "bold" : "normal",
              })}
            >
              <div className="menu">
                <div>
                  <Icon
                    icon="fluent:flash-16-filled"
                    width="20"
                    height="20"
                    style={{ color: "black" }}
                  />
                </div>
                <div className="Side-Title-Name">Flashnews</div>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/ProductCategoryMaster"
              style={({ isActive }) => ({
                textDecoration: "none",
                color: isActive ? "grey" : "inherit",
                fontWeight: isActive ? "bold" : "normal",
              })}
            >
              <div className="menu">
                <div>
                  <Icon
                    icon="bxs:category"
                    width="20"
                    height="20"
                    style={{ color: "black" }}
                  />
                </div>
                <div className="Side-Title-Name">Product Category Master</div>
              </div>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
