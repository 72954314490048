import React, { useEffect, useState, useRef, useContext } from "react";
import Sidebar from "../Sidebar";
// import "./newsandnotice.css";
import "../../css/style.css";
import "../../css/whatsapp.css";
import "../../css/mobile.css"
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
// import Modal from "./Modal";
import Backend_Url from "../../config/config";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import Profile from "../../Assets/Images/men_pic.webp";
import ModalAttachMenu from "./ModalAttachMenu";
import ModalHeaderSideBar from "./ModalHeaderSideBar";
import ModalRepliedChat from "./ModalRepliedChat";
import WhatsappBtnMenu from "./whatsappBtnMenu";
import ModalChatHeader from "./ModalChatHeader";
import MyContext from "../../context/Mycontext";



const WhatsappWeb = () => {
    //**************************************************** */

    const [selectedContact, setSelectedContact] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sidebarHidden, setSidebarHidden] = useState(false);

    // const [apiData, setApiData] = useState()
    const [filteredContacts, setFilteredContacts] = useState([]);
    // const [messageData, setMessageData] = useState()
    // const [lstmessageData, setLstMessageData] = useState()
    const [cookies] = useCookies(['token']);
    const token = cookies.token;
    const [filteredData, setFilteredData] = useState([]);
    const [apiset, setApiSet] = useState()
    const [message, setMessage] = useState('');
    const [responseData, setResponseData] = useState('');
    const [page, setPage] = useState(1);
    // const [hasMore, setHasMore] = useState(true);
    // const [ws, setWs] = useState(null);
    const [apiData, setApiData] = useState({ contacts: [], totalPages: 1, currentPage: 1 });
    const [loading, setLoading] = useState(false);
    // const [hasMorec, setHasMorec] = useState(true);
    const navigate = useNavigate();
    const [imageUrls, setImageUrls] = useState({});
    const [loadingImageIds, setLoadingImageIds] = useState(new Set());

    const { fetchContactMessages,
        hasMore,
        lstmessageData,
        messageData,
        setMessageData,
        setHasMore,
        fetchlastmessage,
    } = useContext(MyContext)


    const loadMoreMessages = (contact) => {
        // debugger
        if (!hasMore) return;
        fetchContactMessages(contact.wa_id, page + 1).then(() => {
            setPage(prevPage => prevPage + 1);
        });

        // fetchMsgData(contact.wa_id, page + 1).then(() => {
        //     setPage(prevPage => prevPage + 1);
        // });
    };

    const openModal = (contact) => {
        // debugger
        setSelectedContact(contact);
        setMessageData({ messages: [] });
        // fetchMsgData(contact.wa_id, 1)
        fetchContactMessages(contact.wa_id, 1)
        setIsModalOpen(true);
        setSidebarHidden(true);
        readMessage(contact.wa_id)
    };


    const handleScroll = (event) => {

        const element = event.target;
        if (element.scrollTop === 0) {
            loadMoreMessages(selectedContact);
        }
    };

    useEffect(() => {
        console.log("selectedContact", selectedContact);
    }, [selectedContact]);
    useEffect(() => {
        console.log("Message Data", messageData);
    }, [messageData]);
    useEffect(() => {
        console.log("Api Data", apiData);
    }, [apiData]);
    useEffect(() => {
        console.log("Filtererd Data", filteredData);
    }, [filteredData]);

    const readMessage = async (wa_id) => {
        // debugger
        try {
            const response = await axios.patch(`${Backend_Url}/whatsapp/v1/messages/mark-all-read?contactWaId=${wa_id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (response.status === 200) {
                fetchlastmessage();
                // contactProcess()
                console.log(response.data)
            }
        } catch (error) {
            console.error(error)
            throw error
        }
    }


    // const fetchMsgData = async (wa_id, page) => {
    //     // debugger
    //     const token = cookies.token;
    //     if (!token) {
    //         console.error('No token found');
    //         return;
    //     }
    //     try {
    //         const response = await axios.get(`${Backend_Url}/whatsapp/v1/contacts/${wa_id}/getmessages/all?page=${page}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });
    //         // if (response.data.messages.length > 0) {
    //         //     setMessageData(response.data)
    //         //     console.log(response);
    //         // }
    //         if (response.data.messages.length > 0) {


    //             setMessageData(prevData => {
    //                 const existingMessages = prevData?.messages || [];
    //                 const existingMessageIds = new Set(existingMessages.map(msg => msg.id));
    //                 const newMessages = response.data.messages.filter(msg => !existingMessageIds.has(msg.id));

    //                 return {
    //                     ...prevData,
    //                     messages: [...existingMessages, ...newMessages].sort((a, b) => a.timestamp - b.timestamp),
    //                 };
    //             });
    //             console.log(response);
    //         } else {
    //             setHasMore(false);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };


    useEffect(() => {
        const fetchApiSetting = async () => {

            try {
                const response = await axios.get(`${Backend_Url}/whatsapp/v1/whatsapp-api`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response) {
                    console.log(response);
                    const defaultApi = response.data.find(item => item.IsDefault);
                    setApiSet(defaultApi)
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchApiSetting();
    }, []);


    const timestampToTime = (timestamp) => {
        const date = new Date(timestamp * 1000);

        // Extract date components
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Months are zero-indexed
        const day = date.getDate();

        // Extract time components
        let hours = date.getHours();
        const minutes = date.getMinutes();

        // Determine AM/PM and adjust hours
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // Convert '0' to '12' for midnight

        // Format with leading zeros if needed
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        // Combine date and time with AM/PM
        return `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes} ${ampm}`;
    };


    const closeModal = () => {
        setSelectedContact(null);
        setIsModalOpen(false);
        setSidebarHidden(false);
    };


    //get all contacts
    // useEffect(() => {
    //     const fetchData = async () => {
    //         const token = cookies.token;
    //         if (!token) {
    //             console.error('No token found');
    //             return;
    //         }

    //         try {
    //             const response = await axios.get(`${Backend_Url}/whatsapp/v1/contacts/getall`, {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             });

    //             if (response.status === 200) {
    //                 console.log(response);
    //                 setApiData(response.data)
    //             } else {
    //                 console.error('Failed to fetch contacts', response.data);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching contacts:', error);
    //         }
    //     };
    //     fetchData();
    // }, []);

    useEffect(() => {
        fetchData(1);
    }, []);


    const fetchData = async (page = 1) => {
        if (loading) return;
        // debugger
        setLoading(true);
        const token = cookies.token;
        if (!token) {
            console.error('No token found');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${Backend_Url}/contacts/getallwithPage?page=${page}&limit=500`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('API Response:', response.data);
            if (response.status === 200) {
                const newContacts = response.data.contacts;
                setApiData(prevData => {

                    const contactMap = new Map(prevData.contacts.map(contact => [contact.wa_id, contact]));
                    newContacts.forEach(contact => contactMap.set(contact.wa_id, contact));

                    return {
                        contacts: Array.from(contactMap.values()),
                        totalPages: response.data.totalPages,
                        currentPage: page
                    };
                });
                setHasMore(page < response.data.totalPages);

            } else {
                console.error('Failed to fetch contacts', response.data);
            }
        } catch (error) {
            console.error('Error fetching contacts:', error);
        } finally {
            setLoading(false);
        }
    };





    // useEffect(() => {
    //     const getLastMessages = () => {
    //         const messagesByContact = {};

    //         if (lstmessageData) {
    //             lstmessageData.forEach(message => {
    //                 const { contactWaId, createdOn, text } = message;
    //                 if (!messagesByContact[contactWaId] || new Date(messagesByContact[contactWaId].createdOn) < new Date(createdOn)) {
    //                     messagesByContact[contactWaId] = message;
    //                 }
    //             });

    //             return messagesByContact;
    //         }
    //         return {};
    //     }

    //     const lastMessages = getLastMessages();
    //     if (apiData.contacts) {
    //         // debugger
    //         const enrichedContacts = apiData.contacts.map(contact => {
    //             const lastMessage = lastMessages[contact.wa_id];
    //             let lastMessageText = 'No messages';
    //             let lastMessageTime = '';
    //             let unreadCount = 0;

    //             if (lastMessage) {
    //                 const parsedText = JSON.parse(lastMessage.text || '{}');
    //                 const messageBody = parsedText.body || '';

    //                 if (lastMessage.type === 'image') {
    //                     lastMessageText = lastMessage.mediaId ? `Image` : 'Image';
    //                 } else if (lastMessage.type === 'video') {
    //                     lastMessageText = parsedText.video ? `Video: ${parsedText.video.url}` : 'Video';
    //                 } else if (lastMessage.type === 'audio') {
    //                     lastMessageText = parsedText.audio ? `Audio: ${parsedText.audio.url}` : 'Audio';
    //                 } else {
    //                     lastMessageText = messageBody || 'No content';
    //                 }

    //                 lastMessageTime = new Date(lastMessage.createdOn).toLocaleTimeString();
    //                 unreadCount = lastMessage.unreadCount;
    //             }

    //             return {
    //                 ...contact,
    //                 lastMessage: lastMessageText,
    //                 lastMessageTime: lastMessageTime,
    //                 unreadCount: unreadCount
    //             };
    //         });

    //         setFilteredContacts(enrichedContacts);
    //     }
    // }, [apiData, lstmessageData]);

    //Latest Message Data api

    // const fetchLatestMsgData = async () => {
    //     if (!token) {
    //         console.error('No token found');
    //         return;
    //     }
    //     try {
    //         const response = await axios.get(`${Backend_Url}/whatsapp/v1/messages/latest?status=unread`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });
    //         if (response) {
    //             console.log(response.data)
    //             setLstMessageData(response.data)
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    // useEffect(() => {
    //     fetchLatestMsgData();
    //     const intervalId = setInterval(fetchLatestMsgData, 10000); // Change the interval as needed

    //     return () => clearInterval(intervalId);
    // }, []);



    // useEffect(() => {
    //     if (!token) {
    //         console.error('No token found');
    //         return;
    //     }

    //     const createWebSocketConnection = () => {
    //         const socket = new WebSocket(`${Backend_Url}`);
    //         setWs(socket);

    //         socket.onopen = () => {
    //             console.log('WebSocket connection opened');
    //             socket.send(JSON.stringify({ type: 'auth', token }));
    //         };

    //         socket.onmessage = (event) => {
    //             const data = JSON.parse(event.data);
    //             if (data.error) {
    //                 console.error(data.error);
    //             } else {

    //                 if (data.type === 'latestMessages') {
    //                     setLstMessageData(data.messages);
    //                 } else if (data.type === 'contactMessages') {
    //                     // setMessageData(data);

    //                     if (data.messages.length > 0) {


    //                         setMessageData(prevData => {
    //                             const existingMessages = prevData?.messages || [];
    //                             const existingMessageIds = new Set(existingMessages.map(msg => msg.id));
    //                             const newMessages = data.messages.filter(msg => !existingMessageIds.has(msg.id));

    //                             return {
    //                                 ...prevData,
    //                                 messages: [...existingMessages, ...newMessages].sort((a, b) => a.timestamp - b.timestamp),
    //                             };
    //                         });

    //                     } else {
    //                         setHasMore(false);
    //                     }
    //                 }
    //             }
    //         };

    //         socket.onerror = (error) => {
    //             console.error('WebSocket error:', error);
    //         };

    //         socket.onclose = () => {
    //             console.log('WebSocket connection closed');
    //         };
    //     };

    //     setInterval(() => {
    //         createWebSocketConnection();
    //     }, 1000);

    //     return () => {
    //         if (ws) {
    //             ws.close();
    //         }
    //     };
    // }, []);

    // const fetchContactMessages = (wa_id, page) => {
    //     if (ws) {
    //         ws.send(JSON.stringify({ type: 'getContactMessages', wa_id, page }));
    //     }
    // };
    const [searchQuery, setSearchQuery] = useState('');

    const contactProcess = () => {
        const MAX_MESSAGE_LENGTH = 25;
        const getLastMessages = () => {
            const messagesByContact = {};

            if (lstmessageData) {
                lstmessageData.forEach(message => {
                    const { contactWaId, createdOn, text } = message;
                    if (!messagesByContact[contactWaId] || new Date(messagesByContact[contactWaId].createdOn) < new Date(createdOn)) {
                        messagesByContact[contactWaId] = message;
                    }
                });

                return messagesByContact;
            }
            return {};
        }

        const lastMessages = getLastMessages();
        if (apiData.contacts) {
            const enrichedContacts = apiData.contacts.map(contact => {
                const lastMessage = lastMessages[contact.wa_id];
                let lastMessageText = 'No messages';
                let lastMessageTime = '';
                let unreadCount = 0;

                if (lastMessage) {
                    const parsedText = JSON.parse(lastMessage.text || '{}');
                    const messageBody = parsedText.body || '';

                    if (lastMessage.type === 'image') {
                        lastMessageText = lastMessage.mediaId ? 'Image' : 'Image';
                    } else if (lastMessage.type === 'video') {
                        lastMessageText = parsedText.video ? `Video: ${parsedText.video.url}` : 'Video';
                    } else if (lastMessage.type === 'audio') {
                        lastMessageText = parsedText.audio ? `Audio: ${parsedText.audio.url}` : 'Audio';
                    } else {
                        lastMessageText = messageBody || 'No content';
                    }
                    if (lastMessageText.length > MAX_MESSAGE_LENGTH) {
                        lastMessageText = lastMessageText.substring(0, MAX_MESSAGE_LENGTH) + '...';
                    }
                    const createdDate = new Date(lastMessage.createdOn);
                    lastMessageTime = `${createdDate.toLocaleDateString()}\n${createdDate.toLocaleTimeString()}`;
                    // lastMessageTime = new Date(lastMessage.createdOn).toLocaleTimeString();
                    unreadCount = lastMessage.unreadCount;
                }

                return {
                    ...contact,
                    lastMessage: lastMessageText,
                    lastMessageTime: lastMessageTime,
                    unreadCount: unreadCount
                };
            });

            const enrichedContactsMap = new Map(enrichedContacts.map(contact => [contact.wa_id, contact]));

            const updatedContacts = filteredContacts.map(contact => {
                if (enrichedContactsMap.has(contact.wa_id)) {
                    return enrichedContactsMap.get(contact.wa_id);
                }
                return contact;
            });

            const allContacts = [
                ...updatedContacts,
                ...enrichedContacts.filter(contact => !filteredContacts.some(c => c.wa_id === contact.wa_id))
            ];

            const sortedContacts = allContacts.sort((a, b) => {
                if (a.unreadCount > 0 && b.unreadCount === 0) {
                    return -1;
                }
                if (a.unreadCount === 0 && b.unreadCount > 0) {
                    return 1;
                }
                return new Date(b.lastMessageTime) - new Date(a.lastMessageTime);
            });

            const searchedContacts = sortedContacts.filter(contact => {
                const searchLower = searchQuery.toLowerCase();
                const contactName = contact.Profile?.toLowerCase() || '';
                const contactWaId = contact.wa_id || '';
                return contactName.includes(searchLower) || contactWaId.includes(searchLower);
            });
            setFilteredContacts(searchedContacts);
        }
    }

    useEffect(() => {
        debugger
        contactProcess()
    }, [apiData, lstmessageData, searchQuery]);



    useEffect(() => {
        // const intervalId = setInterval(() => {
        if (selectedContact != null) {
            fetchContactMessages(selectedContact.wa_id, page)
        }
        // }, 1500);
        // return () => clearInterval(intervalId);
    }, [selectedContact, lstmessageData]);

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         fetchlastmessage()
    //     }, 1500);
    //     return () => clearInterval(intervalId);
    // }, []);

    // useEffect(() => {
    //     const intervalId = setInterval(() => {

    //         if (selectedContact != null) {
    //             fetchMsgData(selectedContact.wa_id, page);
    //         }
    //     }, 10000);

    //     return () => clearInterval(intervalId);
    // }, []);


    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    /*********************************************************** */

    const [isMenuOpenHeader, setIsMenuOpenHeader] = useState(false);
    const menuRef1 = useRef(null);

    const toggleMenuHeader = () => {
        setIsMenuOpenHeader(!isMenuOpenHeader);
    };

    const handleClickOutsideHeader = (event) => {
        if (menuRef1.current && !menuRef1.current.contains(event.target)) {
            setIsMenuOpenHeader(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideHeader);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideHeader);
        };
    }, []);


    /******************************************************** */

    const [isModalOpenReply, setIsModalOpenReply] = useState(false);
    const [isMenuOpenReply, setIsMenuOpenReply] = useState(false);
    const [selectedMessageId, setSelectedMessageId] = useState(null);
    const menuRef3 = useRef(null);

    const toggleMenuReply = (messageId) => {
        setSelectedMessageId(messageId);
        setIsMenuOpenReply(!isMenuOpen);
    };

    const handleClickOutsideReply = (event) => {
        if (menuRef3.current && !menuRef3.current.contains(event.target)) {
            setIsMenuOpenReply(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideReply);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideReply);
        };
    }, []);

    const getTickMark = (status) => {
        if (status === 'read') return '✓✓'; // Double tick
        if (status === 'unread') return '✓'; // Single tick
        return '';
    };


    const handleBackClick = () => {
        setIsModalOpen(false);
        setSidebarHidden(false);
    };

    /********************************************* */

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 750);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    /********************************************************** */

    const [isMenuOpenChatHeader, setIsMenuOpenChatHeader] = useState(false);
    const menuRef4 = useRef(null);

    const toggleMenuChatHeader = () => {
        setIsMenuOpenChatHeader(!isMenuOpenChatHeader);
    };

    const handleClickOutsideChatHeader = (event) => {
        if (menuRef4.current && !menuRef4.current.contains(event.target)) {
            setIsMenuOpenChatHeader(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideChatHeader);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideChatHeader);
        };
    }, []);

    /*************************************************** */



    const handleInputChange = (event) => {
        setMessage(event.target.value);
    };

    console.log("Response Data", responseData);

    ////To ge the last messageID
    useEffect(() => {
        const fetchResponseData = async () => {
            try {
                const response = await axios.get(`${Backend_Url}/whatsapp/v1/messageresponse`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response) {
                    console.log(response);
                    setResponseData(response.data)

                    const getLastEntries = (entries) => {
                        const result = entries.reduce((acc, current) => {
                            const existingEntry = acc[current.wa_id];
                            if (!existingEntry || new Date(existingEntry.createdOn) < new Date(current.createdOn)) {
                                acc[current.wa_id] = current;
                            }
                            return acc;
                        }, {});

                        return Object.values(result);
                    };

                    setFilteredData(getLastEntries(response.data));
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchResponseData();
    }, []);

    useEffect(() => {
        // Your code here
        console.log("Filter Contact", filteredContacts)
    }, [filteredContacts]);




    const handleSendClick = async () => {

        setMessage('');

        // // debugger
        const apiUrl = apiset.URL;

        const msgId = filteredData.filter(item => item.wa_id === selectedContact.wa_id)
        console.log(msgId);
        const payload = {
            messaging_product: 'whatsapp',
            recipient_type: 'individual',
            to: selectedContact.wa_id,
            type: 'text',
            text: {
                body: message
            }
        };

        // const payload = {
        //     messaging_product: "whatsapp",
        //     context: {
        //         message_id: msgId.length > 0 ? msgId[0].message_id : ""
        //     },
        //     to: selectedContact.wa_id,
        //     type: "text",
        //     text: {
        //         preview_url: false,
        //         body: message
        //     }
        // }

        const Token = apiset.RequestHeaderValueField1

        const headers = {
            'Authorization': `${Token}`,
            'Content-Type': 'application/json'
        };

        try {
            const response = await axios.post(apiUrl, payload, { headers });
            if (response.status === 200) {
                // alert('Message sent successfully!');
                const payload = {
                    input: response.data.contacts[0].input,
                    wa_id: response.data.contacts[0].wa_id,
                    message_id: response.data.messages[0].id,
                    text: {
                        body: message
                    }
                }
                const token = cookies.token;
                const saveResponse = await axios.post(`${Backend_Url}/whatsapp/v1/messageresponse/save`, payload, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                console.log(saveResponse)
                if (saveResponse) {
                    // fetchMsgData(selectedContact.wa_id, page)
                }
            }
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error sending message:', error.response ? error.response.data : error.message);
            alert('Failed to send message.');
        }
    };




    const ImageWithAuth = ({ imageUrl, token }) => {
        const [imgSrc, setImgSrc] = useState('');
        console.log("Image Source", imgSrc);
        useEffect(() => {
            const fetchImage = async () => {
                // // debugger
                try {

                    const response = await axios.post(`${Backend_Url}/helper/proxy-image`, { imageUrl, token }, {
                        responseType: 'arraybuffer'
                    });

                    if (response.status === 200) {
                        const blob = new Blob([response.data], { type: 'image/jpeg' });
                        const imgURL = URL.createObjectURL(blob);
                        setImgSrc(imgURL);
                    } else {
                        console.error('Failed to fetch image:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error fetching image:', error);
                }
            };

            fetchImage();
        }, []);

        return (
            <div>
                {imgSrc ? (
                    <img src={imgSrc} alt="Authenticated Content" width={"250px"} height={"300px"} />
                ) : (
                    <p>Loading image...</p>
                )}
            </div>
        );
    };



    const fetchImageUrl = async (mediaId) => {
        if (!mediaId) return;

        setLoadingImageIds((prev) => new Set(prev).add(mediaId)); // Add mediaId to loading state

        try {
            const url = `${apiset.Url}/${apiset.Version}`;
            const response = await axios.get(`${url}/${mediaId}`, {
                headers: {
                    Authorization: apiset.RequestHeaderValueField1,
                },
            });

            if (response && response.data && response.data.url) {
                setImageUrls((prev) => ({ ...prev, [mediaId]: response.data.url }));
            }
        } catch (error) {
            console.error('Error fetching image URL:', error);
        } finally {
            setLoadingImageIds((prev) => {
                const newSet = new Set(prev);
                newSet.delete(mediaId);
                return newSet;
            });
        }
    };

    useEffect(() => {
        messageData && messageData.messages.forEach((message) => {
            if (message.mediaId && !imageUrls[message.mediaId]) {
                fetchImageUrl(message.mediaId);
            }
        });
    }, [messageData]);

    const endOfMessagesRef = useRef(null);

    // useEffect(() => {
    //     // // debugger
    //     if (endOfMessagesRef.current) {
    //         endOfMessagesRef.current.scrollIntoView({ behavior: 'auto' });
    //     }
    // }, [messageData]);



    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                navigate(-1);
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [navigate]);

    const getInitials = (profile) => {

        if (!profile) return '';

        const names = profile?.split(' ');
        const firstInitial = names[0] ? names[0][0] : '';
        const lastInitial = names[1] ? names[1][0] : '';

        return `${firstInitial}${lastInitial}`.toUpperCase();
    };

    return (
        <div className="app">
            <div className="app__body ">
                <div className="Flex-Whatsapp-Body">

                    <div className={`sidebar-wtp ${sidebarHidden ? 'hidden' : ''}`}>
                        <div className="sidebar__header">
                            {isMobile ? (
                                <>
                                    <div className="sidebar__headerLeft">
                                        <span className="sidebar__headerName">WhatsApp</span>
                                    </div>
                                    <div className="Flex-right-Icons">
                                        <div className="sidebar__headerRight">
                                            <button className="sidebar__headerButton">
                                                <Icon className="Icon-Size-wtp" icon="mdi:qrcode-scan" />
                                            </button>
                                        </div>
                                        <div className="sidebar__headerLeft">
                                            <button className="sidebar__headerButton">
                                                <Icon className="Icon-Size-wtp" icon="mdi:camera-outline" />
                                            </button>
                                        </div>
                                        <div className="sidebar__headerRight">
                                            <button className="sidebar__headerButton Icon-header-menu" onClick={toggleMenuHeader}>
                                                <Icon className="Icon-Size-wtp" icon="bi:three-dots-vertical" />
                                                {isMenuOpenHeader && (
                                                    <div className="modal-header" ref={menuRef1}>
                                                        <ModalHeaderSideBar />
                                                    </div>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {/* <img src={Profile} alt="Profile" className="sidebar__headerProfileImage" /> */}
                                    <div className="sidebar__headerProfileImage" style={{
                                        background: 'gray',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '50%',
                                        width: '40px',
                                        height: '40px',
                                        fontSize: '18px',
                                    }}>
                                        A
                                    </div>
                                    <div className="sidebar__headerRight">
                                        {/* <button className="sidebar__headerButton">
                                            <Icon className="Icon-Size-wtp" icon="ant-design:sound-outlined" />
                                        </button>
                                        <button className="sidebar__headerButton">
                                            <Icon className="Icon-Size-wtp" icon="mdi:person-group" />
                                        </button>
                                        <button className="sidebar__headerButton">
                                            <Icon className="Icon-Size-wtp" icon="f7:status" />
                                        </button>
                                        <button className="sidebar__headerButton">
                                            <Icon className="Icon-Size-wtp" icon="majesticons:chat-status-line" />
                                        </button>
                                        <button className="sidebar__headerButton">
                                            <Icon className="Icon-Size-wtp" icon="mdi:message-plus-outline" />
                                        </button> */}
                                        {/* <button className="sidebar__headerButton Icon-header-menu" onClick={toggleMenuHeader}>
                                            <Icon className="Icon-Size-wtp" icon="bi:three-dots-vertical" />
                                            {isMenuOpenHeader && (
                                                <div className="modal-header" ref={menuRef1}>
                                                    <ModalHeaderSideBar />
                                                </div>
                                            )}
                                        </button> */}
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="sidebar__search">
                            <div className="Icon-search-wtp"><Icon icon="material-symbols:search" /></div>
                            <input className="Input-Box-whatsapp" placeholder="Search or start new chat" type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                        <div className="chat">
                            <div className="sidebar__chats">
                                {/* {apiData && apiData.contacts.map(contact => (
                                    <div
                                        key={contact.wa_id}
                                        className="sidebar__chat"
                                        onClick={() => openModal(contact)}
                                    >
                                        <img src={contact.Profile} alt="Profile" className="sidebar__headerProfileImage" />
                                        <div className="Msg-width-wtp">
                                            <div className="chat-person-name">{contact.Profile.name || contact.wa_id}</div>
                                            {contact.msg && <div className="chat-person-last-message">{contact.msg}</div>}
                                        </div>
                                        <div className="Chat_Time_Wtp">{contact.time}</div>
                                    </div>
                                ))} */}
                                {filteredContacts && filteredContacts.map(contact => (
                                    <div
                                        key={contact.wa_id}
                                        className="sidebar__chat"
                                        onClick={() => openModal(contact)}
                                    >
                                        {/* <img src={contact.Profile.picture || 'default-profile.png'} alt="Profile" className="sidebar__headerProfileImage" /> */}

                                        {/* <div alt="Profile" className="sidebar__headerProfileImage" style={{ background: "black" }} /> */}

                                        <div
                                            className="sidebar__headerProfileImage"
                                            style={{
                                                background: 'gray',
                                                color: 'white',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '50%',
                                                width: '40px',
                                                height: '40px',
                                                fontSize: '18px',
                                            }}
                                        >
                                            {contact.Profile != ' ' ? getInitials(contact.Profile) : getInitials('Wa User')}
                                        </div>

                                        <div className="Msg-width-wtp">
                                            <div style={{ display: "flex" }}>

                                                <div className="chat-person-name">{contact.Profile != ' ' ? contact.Profile : 'Wa User'}</div>
                                                <div className="chat-contact">{`(${contact.wa_id})`}</div>
                                            </div>
                                            <div className="chat-person-last-message">{contact.lastMessage}</div>
                                        </div>
                                        <div style={{ display: "flex", gap: "20px" }}>
                                            <div className="Chat_Time_Wtp">{contact.lastMessageTime}</div>
                                            {contact.unreadCount > 0 && <div className="unread-count">{contact.unreadCount}</div>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className={`chat-container ${isModalOpen ? 'chat-container--modal-open' : ''} ${sidebarHidden ? 'show' : ''}`}>

                        {isModalOpen && (
                            <div className="chat">
                                <div className="chat__header">
                                    <button className="chat__headerBackButton" onClick={handleBackClick}>
                                        <Icon className="Icon-Size-wtp" icon="mdi:arrow-left" />
                                    </button>
                                    <img src={Profile} alt="Contact" className="chat__headerImage" />
                                    <div className="chat__headerInfo">
                                        <h3>{selectedContact.Profile}</h3>
                                        {/*<p className="">Last seen today at 06:30 PM</p>*/}
                                    </div>
                                    <div className="chat__headerRight">
                                        {/* <button className="chat__headerButton">
                                            <Icon className="Icon-Size-wtp" icon="fluent:call-16-regular" />
                                        </button>
                                        <button className="chat__headerButton">
                                            <Icon className="Icon-Size-wtp" icon="icons8:video-call" />
                                        </button>
                                        <button className="chat__headerButton">
                                            <Icon className="Icon-Size-wtp" icon="material-symbols:search" />
                                        </button> */}
                                        <button className="chat__headerButton" onClick={toggleMenuChatHeader}>
                                            <Icon className="Icon-Size-wtp" icon="bi:three-dots-vertical" />
                                            {isMenuOpenChatHeader && (
                                                <div ref={menuRef4}>
                                                    <ModalChatHeader />
                                                </div>
                                            )}
                                        </button>
                                    </div>
                                </div>
                                <div className="chat__body" onScroll={handleScroll}>
                                    {/* {messageData && messageData.map(message => {
                                        const time = timestampToTime(message.timestamp);

                                        return (
                                            <div
                                                key={message.id}
                                                className={`chat__message-container ${message.from === 'self' ? 'chat__message-container--self' : ''}`}
                                                onClick={() => toggleMenuReply(message.id)}
                                            >
                                                <p className="chat__message">
                                                    {message.type === 'text' && message.text.body ? message.text.body : getImageUrl(message.mediaId)}
                                                    <br />
                                                    <span className="chat__message-time">{time}</span>
                                                    <span className="chat__message-status">{getTickMark(message.status)}</span>
                                                    <span className="chat__message-arrow">
                                                        <Icon icon="ep:arrow-down" />
                                                    </span>
                                                    {isMenuOpenReply && selectedMessageId === message.id && (
                                                        <div ref={menuRef3}>
                                                            <ModalRepliedChat />
                                                        </div>
                                                    )}
                                                </p>
                                            </div>
                                        );
                                    })} */}

                                    {messageData && messageData.messages.length > 0 && messageData.messages
                                        .map((message, index) => {
                                            const time = timestampToTime(message.timestamp);
                                            const imageUrl = imageUrls[message.mediaId];

                                            return (
                                                <div
                                                    key={message.id}
                                                    className={`chat__message-container ${message.from === 'self' ? 'chat__message-container--self' : ''}`}
                                                    onClick={() => toggleMenuReply(message.id)}
                                                >
                                                    <p className="chat__message">
                                                        {message.type === 'text' && message.text.body ? (
                                                            message.text.body
                                                        ) : (
                                                            <>
                                                                {loadingImageIds.has(message.mediaId) ? (
                                                                    <span>Loading...</span>
                                                                ) : imageUrl ? (
                                                                    <ImageWithAuth token={apiset.RequestHeaderValueField1} imageUrl={imageUrl} />
                                                                ) : (
                                                                    <span>No image available</span>
                                                                )}
                                                            </>
                                                        )}
                                                        <br />
                                                        <span className="chat__message-time">{time}</span>
                                                        {/* <span className="chat__message-status">{getTickMark(message.status)}</span> */}
                                                        <span className="chat__message-arrow">
                                                            {/* <Icon icon="ep:arrow-down" /> */}
                                                        </span>
                                                    </p>
                                                </div>
                                            );
                                        })
                                    }
                                    <div ref={endOfMessagesRef} /> {/* This div acts as the scroll anchor */}
                                </div>
                                <div className="chat__footer flex-footer">
                                    <div className="Icon-margin-send">
                                        <Icon icon="la:laugh-solid" />
                                    </div>
                                    {/* <div className="Icon-margin-send" onClick={toggleMenu}>
                                    <Icon icon="ic:baseline-plus" />
                                </div> */}
                                    <div className="Icon-margin-send Icon-header-menu" onClick={toggleMenu}>
                                        <Icon icon={isMenuOpen ? "ic:baseline-close" : "ic:baseline-plus"} />
                                        {isMenuOpen && (
                                            <div className="Attach-menu" ref={menuRef}>
                                                <ModalAttachMenu />
                                            </div>
                                        )}
                                    </div>
                                    <div className="Input-Box-Chat">
                                        <input className="Input-text-chatting" value={message} onChange={handleInputChange} placeholder="Type a message" type="text" />
                                    </div>
                                    <div className="Icon-margin-send">
                                        {message ? (
                                            <button className="chat__sendButton" onClick={handleSendClick}>
                                                <Icon icon="carbon:send" />
                                            </button>
                                        ) : (
                                            <button className="chat__sendButton">
                                                <Icon icon="mdi:microphone" />
                                            </button>
                                        )}
                                    </div>

                                </div>

                            </div>
                        )}
                    </div>

                </div>
            </div>
            {!isModalOpen && (
                <WhatsappBtnMenu />
            )}
        </div>
    );
};

export default WhatsappWeb;
