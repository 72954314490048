import React, { useEffect, useState, useRef } from "react";
import "../../css/style.css";
import "../../css/whatsapp.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";



const ModalHeaderSideBar = () => {
    //**************************************************** */

    return (

        <div className="header-menu">
            <div className="header-menu-item">Business Tools</div>
            <div className="header-menu-item">New Group</div>
            <div className="header-menu-item">Profile</div>
            <div className="header-menu-item">Advertise on Facebook</div>
            <div className="header-menu-item">Catalog</div>
            <div className="header-menu-item">Quick Replies</div>
            <div className="header-menu-item">Starred Messages</div>
            <div className="header-menu-item">Labels</div>
            <div className="header-menu-item">Settings</div>
            <div className="header-menu-item">Logout</div>
        </div>


    );
};

export default ModalHeaderSideBar;
