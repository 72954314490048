import React, { useEffect, useState, useRef } from "react";
import "../../css/style.css";
import "../../css/whatsapp.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
// import Modal from "./Modal";



const ModalChatHeader = () => {
    //**************************************************** */

    return (

        <div className="chat__headerMenu">
        <button className="chat__headerMenuItem">Contact Info</button>
        <button className="chat__headerMenuItem">Select Messages</button>
        <button className="chat__headerMenuItem">Close Chat</button>
        <button className="chat__headerMenuItem">Mute Notifications</button>
        <button className="chat__headerMenuItem">Disappearing Messages</button>
        <button className="chat__headerMenuItem">Clear Chat</button>
        <button className="chat__headerMenuItem">Delete Chat</button>
        <button className="chat__headerMenuItem">Report</button>
        <button className="chat__headerMenuItem">Block</button>
      </div>

    );
};

export default ModalChatHeader;
