import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
// import "./newsandnotice.css";
import "../../css/style.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
// import Modal from "./Modal";
import Backend_Url from "../../config/config";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    //**************************************************** */

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [token, setToken] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();


    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };


    const handleResetPassword = async (e) => {
        e.preventDefault();
        setMessage("");
        setError("");
        try {
            const response = await axios.post(`${Backend_Url}/users/reset-password/${token}`, { newPassword });
            if (response.data.message) {
                setMessage(response.data.message);
                setTimeout(() => {
                    navigate("/UserLogin")   
                   }, 500);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError("An error occurred. Please try again.");
            }
        }
    };



    return (
        <div className="App">
            <div className="body-content">
                <div className="Flex-Login-User">
                    <div class="Login-container">
                        <div class="heading">Reset Password</div>
                        <div action="" class="form">
                            <input required="" className="input-Box-login" type="text" name="token" id="token"  value={token}  onChange={(e) => setToken(e.target.value)} placeholder="Token" />
                            <input required="" className="input-Box-login"  type={passwordVisible ? 'text' : 'text'} name="password" id="password"  value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="New Password" />
                            <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                            </span>
                            <button className="login-button" type="submit" value="Reset Password" onClick={handleResetPassword}>
                                Change Password
                            </button>
                            {message && <div className="success">{message}</div>}
                            {error && <div className="error">{error}</div>}
                            <span className="back-to-signin">
                                <Link to="/UserLogin">
                                    Back to Forgot Password
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
