import axios from "axios";
import React, { useMemo, useRef, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import Backend_Url from "../../config/config";
import Swal from "sweetalert2";

const ModelAddContact = ({ editableItem, setModalAddContact, fetchGrpData, fetchContactData }) => {


    useEffect(() => {
        console.log("editableItem", editableItem);
    }, []);

    // Initial state with one pair of fields
    const [fields, setFields] = useState([
        { placeholder1: "First Name", placeholder2: "Last Name", value1: '', value2: '' },
        { placeholder1: "Phone No (With Country Code)", placeholder2: "Email", value1: '', value2: '' },
        { placeholder1: "Date Of Birth", placeholder2: "Date Of Marriage", value1: '', value2: '' },
    ]);
    const [cookies] = useCookies(["token"]);
    const token = cookies.token
    // Function to add a new pair of fields
    const addField = () => {
        setFields([...fields, { placeholder1: "New Field 1", placeholder2: "New Field 2" }]);
    };

    const [groupId, setGroupId] = useState();

    console.log(fields);
    /********************************************************** */

    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState([]);
    // const [selectedGroup, setSelectedGroup] = useState('');
    // const [selectedOption, setSelectedOption] = useState(null);
    const [checkedOptions, setCheckedOptions] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    // const [checkedOptions, setCheckedOptions] = useState([]);
    const dropdownRef = useRef(null);


    console.log("Checked Options", checkedOptions);
    console.log("Selected Groups", selectedGroups);
    console.log("Group ids", groupId);



    const mapContactToFields = (contact) => {
        return [
            { placeholder1: "First Name", placeholder2: "Last Name", value1: contact.FirstName || '', value2: contact.LastName || '' },
            { placeholder1: "Phone No (With Country Code)", placeholder2: "Email", value1: contact.PhoneNo || '', value2: contact.Email || '' },
            { placeholder1: "Date Of Birth", placeholder2: "Date Of Marriage", value1: contact.DOB || '', value2: contact.DOM || '' },
        ];
    };

    // Use effect to set fields when contact changes
    useEffect(() => {
        if (editableItem) {
            setFields(mapContactToFields(editableItem));
        }
    }, [editableItem]);

    const toggleDropdown = () => {
        setIsOpen(prevState => !prevState);
    };


    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false); // Close dropdown if clicked outside
        }
    };

    const handleCheckboxChange = (wa_id) => {
        setCheckedOptions((prevCheckedOptions) => {
            const newCheckedOptions = prevCheckedOptions.includes(wa_id)
                ? prevCheckedOptions.filter(id => id !== wa_id)
                : [...prevCheckedOptions, wa_id];
            updateSelectedGroups(newCheckedOptions);
            return newCheckedOptions;
        });
    };

    const updateSelectedGroups = (newCheckedOptions) => {
        const selected = data.filter(group => newCheckedOptions.includes(group.wa_id));
        setSelectedGroups(selected);
    };

    useEffect(() => {
        // Add event listener for clicks outside the dropdown
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    /*********************************API CALL******************************************** */

    const [formData, setFormData] = useState({
        fields: [
            { placeholder1: "", placeholder2: "" },
            { placeholder1: "", placeholder2: "" },
            { placeholder1: "", placeholder2: "" }
        ]
    });

    const transformFieldsData = () => {
        const contactData = {
            contacts: []
        };

        // Create a new contact object
        const contact = {};

        fields.forEach((field) => {
            if (field.placeholder1 === "First Name") {
                contact.FirstName = field.value1;
            } else if (field.placeholder1 === "Phone No (With Country Code)") {
                contact.PhoneNo = field.value1;
            } else if (field.placeholder1 === "Date Of Birth") {
                contact.DOB = field.value1;
            }

            if (field.placeholder2 === "Last Name") {
                contact.LastName = field.value2;
            } else if (field.placeholder2 === "Email") {
                contact.Email = field.value2;
            } else if (field.placeholder2 === "Date Of Marriage") {
                contact.DOM = field.value2;
            }
        });

        // Add the contact to the contacts array
        contactData.contacts.push(contact);

        // Return the data in the required format
        return contactData;
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();


    //     // debugger

    //     if (!token) {
    //         console.error('No token found');
    //         return;
    //     }
    //     const contactsData = fields.map(field => ({
    //         firstName: field.value1,
    //         lastName: field.value2,
    //         phoneNo: field.value1,
    //         email: field.value2,
    //         DOB: field.value1,
    //         DOM: field.value2
    //     }));
    //     const requestData = transformFieldsData();

    //     try {
    //         const response = await axios.post(`${Backend_Url}/contacts/contacts/create `, requestData, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         });

    //         if (response.status === 201) {
    //             alert("Created Successfully")
    //         }

    //         if (response.ok) {
    //             console.log('Success:');
    //         } else {
    //             console.error('Error:');
    //         }
    //     } catch (error) {
    //         console.error('Network error:', error);
    //     }
    // };

    const handleSubmit = async () => {
        if (!token) {
            console.error('No token found');
            Swal.fire('Error', 'Authorization token is missing.', 'error');
            return;
        }
        // debugger
        try {
            // Transform and prepare the request data
            const requestData = transformFieldsData();
            console.log(requestData);

            const response = await axios.post(`${Backend_Url}/ContactGroups/new/multiple/add-contacts`, { contacts: requestData.contacts, groupIds: checkedOptions }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (response.status === 200) {
                setFields([
                    { placeholder1: "First Name", placeholder2: "Last Name", value1: '', value2: '' },
                    { placeholder1: "Phone No (With Country Code)", placeholder2: "Email", value1: '', value2: '' },
                    { placeholder1: "Date Of Birth", placeholder2: "Date Of Marriage", value1: '', value2: '' },
                ]);
                // fetchContactData()
                fetchGrpData()
                Swal.fire('Success', 'Contacts added to all groups successfully!', 'success');
            } else {
                Swal.fire('Warning', `Some requests failed`, 'warning');
            }

            // const responses = await Promise.all(requests.map(p => p.catch(e => e)));
            // const allSuccessful = responses.every(response => response.status === 201 || 200);
            // if (allSuccessful) {
            //     Swal.fire('Success', 'Contacts added to all groups successfully!', 'success');
            // } else {
            //     const failedResponses = responses.filter(response => response.status !== 201);
            //     const errorMessages = failedResponses.map(response => response.response?.data?.message || 'Unknown error');

            //     Swal.fire('Warning', `Some requests failed: ${errorMessages.join(', ')}`, 'warning');
            // }


            // Provide feedback based on the response
            // if (responses.status === 201) {
            //     Swal.fire('Success', 'Contacts added to the group successfully!', 'success');
            // } else {
            //     Swal.fire('Warning', 'Contacts added with some warnings.', 'warning');
            // }
        } catch (error) {
            // Enhanced error handling
            if (error.response) {

                console.error("Server Error:", error.response.data);
                Swal.fire('Error', `Server Error: ${error.response.data.message || 'Unable to add contacts'}`, 'error');
            } else if (error.request) {

                console.error("Network Error:", error.request);
                Swal.fire('Error', 'Network Error: No response from the server.', 'error');
            } else {
                // Error setting up the request
                console.error("Error:", error.message);
                Swal.fire('Error', `Error: ${error.message}`, 'error');
            }
        }
    };

    const handleUpdate = async () => {
        // debugger
        if (!token) {
            console.error('No token found');
            Swal.fire('Error', 'Authorization token is missing.', 'error');
            return;
        }
        try {
            const requestData = transformFieldsData();
            console.log(requestData);
            const response = await axios.put(`${Backend_Url}/contacts/contacts/${editableItem.wa_id}`, { contacts: requestData.contacts, groupIds: checkedOptions }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
            if (response.status == 200) {
                Swal.fire('Success', 'Contact updated successfully!', 'success');
                setModalAddContact(false)
                fetchGrpData()
            }
        } catch (error) {
            if (error.response) {

                console.error("Server Error:", error.response.data);
                Swal.fire('Error', `Server Error: ${error.response.data.message || 'Unable to add contacts'}`, 'error');
            } else if (error.request) {

                console.error("Network Error:", error.request);
                Swal.fire('Error', 'Network Error: No response from the server.', 'error');
            } else {
                // Error setting up the request
                console.error("Error:", error.message);
                Swal.fire('Error', `Error: ${error.message}`, 'error');
            }
        }
    }

    const handleChange = (index, field, value) => {

        const newFields = [...fields];
        newFields[index] = { ...newFields[index], [field]: value };
        setFields(newFields);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await axios.get(`${Backend_Url}/contactgroups/getall`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setData(response.data); // Set data to state
            } catch (error) {
                console.error('Failed to fetch data:', error);
                // setError(error); // Set error to state
            } finally {
                // setLoading(false); // End loading
            }
        };

        fetchData();
    }, []);

    return (

        <div>
            <div className="Model-Query-Content">
                <div className="Content-padding-Campaigns">
                    <div className="custom-dropdown-select" ref={dropdownRef}>
                        <div className="dropdown-header-select" onClick={toggleDropdown}>
                            <label className="Campaigns-lbl">Under Group</label>
                            {/* <div className="dropdown-selected">
                                {selectedOption || '--Select--'}
                            </div> */}
                        </div>
                        {isOpen && (
                            <div className="dropdown-list-select">
                                {/* <div className="dropdown-option">
                                    <input
                                        type="checkbox"
                                    />
                                    <span>--Select--</span>
                                </div>
                                <div className="dropdown-option">
                                    <input
                                        type="checkbox"
                                        checked={checkedOptions.includes('Name')}
                                        onChange={() => handleCheckboxChange('Name')}
                                    />
                                    <span onClick={() => handleOptionClick('Name')}>Name</span>
                                </div>
                                <div className="dropdown-option">
                                    <input
                                        type="checkbox"
                                        checked={checkedOptions.includes('Address')}
                                        onChange={() => handleCheckboxChange('Address')}
                                    />
                                    <span onClick={() => handleOptionClick('Address')}>Address</span>
                                </div> */}

                                <div className="dropdown-option">
                                    <input
                                        type="checkbox"
                                        checked={checkedOptions.length === data.length}
                                        onChange={() => {
                                            const allChecked = checkedOptions.length === data.length;
                                            const newCheckedOptions = allChecked ? [] : data.map(group => group.wa_id);
                                            setCheckedOptions(newCheckedOptions);
                                            updateSelectedGroups(newCheckedOptions);
                                        }}
                                    />
                                    <span>--Select All--</span>
                                </div>
                                {data.map((group) => (
                                    <div key={group.wa_id} className="dropdown-option">
                                        <input
                                            type="checkbox"
                                            checked={checkedOptions.includes(group.wa_id)}
                                            onChange={() => handleCheckboxChange(group.wa_id)}
                                        />
                                        <span>{group.Name}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {fields.map((field, index) => (
                        <div className="Flex-Input-Campaigns" key={index}>
                            <div className='Input-Box-Compaigns Query-width-name'>
                                <input className="Input-contact-box" type="text" name={`field-${index}-1`} value={field.value1} placeholder={field.placeholder1} onChange={(e) => handleChange(index, 'value1', e.target.value)} />
                            </div>
                            <div className='Input-Box-Compaigns Query-width-name'>
                                <input className="Input-contact-box" type="text" name={`field-${index}-2`} value={field.value2} placeholder={field.placeholder2} onChange={(e) => handleChange(index, 'value2', e.target.value)} />
                            </div>
                        </div>
                    ))}
                    <button onClick={addField}> + Add More Fields</button>
                </div>

                <div className="view-all-container">
                    <button className="view-all-button">
                        Cancel
                    </button>
                    {
                        editableItem ? (
                            <button className="view-all-button" onClick={handleUpdate}>
                                Update Contact
                            </button>
                        ) : (<button className="view-all-button" onClick={handleSubmit}>
                            Save Contact
                        </button>)
                    }

                </div>
            </div>
        </div>
    );
};

export default ModelAddContact;
