import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Backend_Url from '../../config/config';
// import jwtDecode from 'jwt-decode';
import { useCookies } from 'react-cookie';
import WTPSideBar from '../WhatsappCampaigns/WTPSideBar';



const MyForm = () => {
    // Initialize state with default values
    const [cookies] = useCookies(['token']);
    const token = cookies.token;
    const [apiDropdown, setApiDropdown] = useState();
    const [apiSetData, setApiSetData] = useState([])
    const [formData, setFormData] = useState({
        Name: '',
        Url: '',
        Version: '',
        PhoneNumberId: '',
        AppId: '',
        Cnt_Cd: '',
        MobNo: '',
        IsDefault: true,
        RequestHeaderNameField1: '',
        RequestHeaderNameField2: '',
        RequestHeaderNameField3: '',
        RequestHeaderValueField1: '',
        RequestHeaderValueField2: '',
        RequestHeaderValueField3: '',
        RequestParameterNameField1: '',
        RequestParameterNameField2: '',
        RequestParameterNameField3: '',
        RequestParameterValueField1: '',
        RequestParameterValueField2: '',
        RequestParameterValueField3: ''
    });

    console.log(apiDropdown);



    // Handle input change
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        // Handle number fields differently to ensure they are sent as numbers
        if (name === 'PhoneNumberId' || name === 'AppId' || name === 'Cnt_Cd' || name === 'MobNo') {
            setFormData({
                ...formData,
                [name]: value ? Number(value) : '' // Convert value to number if not empty
            });
        } else {
            setFormData({
                ...formData,
                [name]: type === 'checkbox' ? checked : value
            });
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        // debugger
        if (!token) {
            alert('User not authenticated');
            return;
        }
        const decodedToken = decodeJwt(token); // Use a JWT decoding library or function
        const usercode = decodedToken?.usercode;
        if (!usercode) {
            alert('User code not found in token');
            return;
        }
        const formDataWithUserCode = {
            ...formData,
            usercode
        };

        try {
            const response = await axios.put(`${Backend_Url}/whatsapp/v1/update/${apiDropdown}`, formDataWithUserCode,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            if (response.status === 200) { // Check for successful creation
                alert("Updated Successfully");
                fetchDataByid(apiDropdown)
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            alert("Unable To Update");
        }

    }

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        // debugger
        // Assuming you have the JWT token in local storage
        const jwtToken = cookies.token;
        if (!jwtToken) {
            alert('User not authenticated');
            return;
        }

        // Decode JWT token to extract claims
        const decodedToken = decodeJwt(jwtToken); // Use a JWT decoding library or function
        const usercode = decodedToken?.usercode;

        if (!usercode) {
            alert('User code not found in token');
            return;
        }

        // Add userCode to formData
        const formDataWithUserCode = {
            ...formData,
            usercode
        };

        try {
            const response = await axios.post(`${Backend_Url}/whatsapp/v1/create`, formDataWithUserCode,
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
            if (response.status === 201) { // Check for successful creation
                alert("Created Successfully");
                setFormData({
                    Name: '',
                    Url: '',
                    Version: '',
                    PhoneNumberId: '',
                    AppId: '',
                    Cnt_Cd: '',
                    MobNo: '',
                    IsDefault: true,
                    RequestHeaderNameField1: '',
                    RequestHeaderNameField2: '',
                    RequestHeaderNameField3: '',
                    RequestHeaderValueField1: '',
                    RequestHeaderValueField2: '',
                    RequestHeaderValueField3: '',
                    RequestParameterNameField1: '',
                    RequestParameterNameField2: '',
                    RequestParameterNameField3: '',
                    RequestParameterValueField1: '',
                    RequestParameterValueField2: '',
                    RequestParameterValueField3: ''
                });
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            alert(error.response.data.message);
        }
    };

    // Function to decode JWT (you can use a library like jwt-decode)
    const decodeJwt = (token) => {
        try {
            const payload = token.split('.')[1];
            const decoded = atob(payload);
            return JSON.parse(decoded);
        } catch (e) {
            console.error('Error decoding JWT:', e);
            return null;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${Backend_Url}/whatsapp/v1/whatsapp-api`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response) {
                    console.log(response.data);
                    setApiSetData(response.data)
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);//get all method

    const fetchDataByid = async (id) => {
        try {
            const response = await axios.get(`${Backend_Url}/whatsapp/v1/getById/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response) {
                console.log(response.data);
                // setApiSetData(response.data)
                setFormData({
                    Name: response.data.data.Name,
                    Url: response.data.data.Url,
                    Version: response.data.data.Version,
                    PhoneNumberId: response.data.data.PhoneNumberId,
                    AppId: response.data.data.AppId,
                    Cnt_Cd: response.data.data.Cnt_Cd,
                    MobNo: response.data.data.MobNo,
                    IsDefault: response.data.data.IsDefault,
                    RequestHeaderNameField1: response.data.data.RequestHeaderNameField1,
                    RequestHeaderNameField2: response.data.data.RequestHeaderNameField2,
                    RequestHeaderNameField3: response.data.data.RequestHeaderNameField3,
                    RequestHeaderValueField1: response.data.data.RequestHeaderValueField1,
                    RequestHeaderValueField2: response.data.data.RequestHeaderValueField2,
                    RequestHeaderValueField3: response.data.data.RequestHeaderValueField3,
                    RequestParameterNameField1: response.data.data.RequestParameterNameField1,
                    RequestParameterNameField2: response.data.data.RequestParameterNameField2,
                    RequestParameterNameField3: response.data.data.RequestParameterNameField3,
                    RequestParameterValueField1: response.data.data.RequestParameterValueField1,
                    RequestParameterValueField2: response.data.data.RequestParameterValueField2,
                    RequestParameterValueField3: response.data.data.RequestParameterValueField3
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchDataByid(apiDropdown)
    }, [apiDropdown]);


    return (
        <div className="Flex-Head-Right-Compaigns">

            <div>
                <WTPSideBar />
            </div>
            <form className="my-form" onSubmit={handleSubmit}>
                <div className="my-form__group">
                    <label className="my-form__label">Select From Avaliable Settings:</label>
                    <select
                        className="my-form__dropdown"
                        name="dropdown"
                        value={apiDropdown}
                        onChange={(e) => setApiDropdown(e.target.value)}
                    >
                        <option value="">Select an option</option>
                        {apiSetData && apiSetData.map((item, index) => (
                            <option key={index} value={item.id}>
                                {item.Name}
                            </option>
                        ))}
                        {/* <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option> */}
                    </select>
                </div>
                <div className="my-form__group">
                    <label className="my-form__label">Name:</label>
                    <input
                        className="my-form__input-text"
                        type="text"
                        name="Name"
                        value={formData.Name}
                        onChange={handleChange}
                    />
                </div>

                <div className="my-form__group">
                    <label className="my-form__label">Url:</label>
                    <input
                        className="my-form__input-text"
                        type="text"
                        name="Url"
                        value={formData.Url}
                        onChange={handleChange}
                    />
                </div>

                <div className="my-form__group">
                    <label className="my-form__label">Version:</label>
                    <input
                        className="my-form__input-text"
                        type="text"
                        name="Version"
                        value={formData.Version}
                        onChange={handleChange}
                    />
                </div>

                <div className="my-form__flex-group">
                    <div className="my-form__group">
                        <label className="my-form__label">PhoneNumberId:</label>
                        <input
                            className="my-form__input-number"
                            type="number"
                            name="PhoneNumberId"
                            value={formData.PhoneNumberId}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="my-form__group">
                        <label className="my-form__label">AppId:</label>
                        <input
                            className="my-form__input-number"
                            type="number"
                            name="AppId"
                            value={formData.AppId}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="my-form__flex-group">
                    <div className="my-form__group">
                        <label className="my-form__label">Cnt_Cd:</label>
                        <input
                            className="my-form__input-number"
                            type="number"
                            name="Cnt_Cd"
                            value={formData.Cnt_Cd}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="my-form__group">
                        <label className="my-form__label">MobNo:</label>
                        <input
                            className="my-form__input-number"
                            type="number"
                            name="MobNo"
                            value={formData.MobNo}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="my-form__group">
                    <label className="my-form__label">IsDefault:</label>
                    <input
                        className="my-form__checkbox"
                        type="checkbox"
                        name="IsDefault"
                        checked={formData.IsDefault}
                        onChange={handleChange}
                    />
                </div>

                {/* Request Header Fields */}
                <div className="my-form__flex-group">
                    <div className="my-form__group">
                        <label className="my-form__label">Request Header Name</label>
                        <input
                            className="my-form__input-text"
                            type="text"
                            name="RequestHeaderNameField1"
                            value={formData.RequestHeaderNameField1}
                            onChange={handleChange}
                            placeholder='Header Name'
                        />
                    </div>

                    <div className="my-form__group">
                        <label className="my-form__label"> Value </label>
                        <input
                            className="my-form__input-text"
                            type="text"
                            name="RequestHeaderValueField1"
                            value={formData.RequestHeaderValueField1}
                            onChange={handleChange}
                            placeholder='Header Value'
                        />
                    </div>
                </div>

                <div className="my-form__flex-group">
                    <div className="my-form__group">
                        {/* <label className="my-form__label">Request Header Name Field 2:</label> */}
                        <input
                            className="my-form__input-text"
                            type="text"
                            name="RequestHeaderNameField2"
                            value={formData.RequestHeaderNameField2}
                            onChange={handleChange}
                            placeholder='Header Name'
                        />
                    </div>

                    <div className="my-form__group">
                        {/* <label className="my-form__label">Request Header Value Field 2:</label> */}
                        <input
                            className="my-form__input-text"
                            type="text"
                            name="RequestHeaderValueField2"
                            value={formData.RequestHeaderValueField2}
                            onChange={handleChange}
                            placeholder='Header Value'
                        />
                    </div>
                </div>

                <div className="my-form__flex-group">
                    <div className="my-form__group">
                        {/* <label className="my-form__label">Request Header Name Field 3:</label> */}
                        <input
                            className="my-form__input-text"
                            type="text"
                            name="RequestHeaderNameField3"
                            value={formData.RequestHeaderNameField3}
                            onChange={handleChange}
                            placeholder='Header Name'
                        />
                    </div>

                    <div className="my-form__group">
                        {/* <label className="my-form__label">Request Header Value Field 3:</label> */}
                        <input
                            className="my-form__input-text"
                            type="text"
                            name="RequestHeaderValueField3"
                            value={formData.RequestHeaderValueField3}
                            onChange={handleChange}
                            placeholder='Header Value'
                        />
                    </div>
                </div>

                {/* Request Parameter Fields */}
                <div className="my-form__flex-group">
                    <div className="my-form__group">
                        <label className="my-form__label">Request Parameter </label>
                        <input
                            className="my-form__input-text"
                            type="text"
                            name="RequestParameterNameField1"
                            value={formData.RequestParameterNameField1}
                            onChange={handleChange}
                            placeholder='Parameter Name'
                        />
                    </div>

                    <div className="my-form__group">
                        <label className="my-form__label">Value</label>
                        <input
                            className="my-form__input-text"
                            type="text"
                            name="RequestParameterValueField1"
                            value={formData.RequestParameterValueField1}
                            onChange={handleChange}
                            placeholder='Parameter Value'
                        />
                    </div>
                </div>

                <div className="my-form__flex-group">
                    <div className="my-form__group">
                        {/* <label className="my-form__label">Request Parameter Name Field 2:</label> */}
                        <input
                            className="my-form__input-text"
                            type="text"
                            name="RequestParameterNameField2"
                            value={formData.RequestParameterNameField2}
                            onChange={handleChange}
                            placeholder='Parameter Name'
                        />
                    </div>

                    <div className="my-form__group">
                        {/* <label className="my-form__label">Request Parameter Value Field 2:</label> */}
                        <input
                            className="my-form__input-text"
                            type="text"
                            name="RequestParameterValueField2"
                            value={formData.RequestParameterValueField2}
                            onChange={handleChange}
                            placeholder='Parameter Value'

                        />
                    </div>
                </div>

                <div className="my-form__flex-group">
                    <div className="my-form__group">
                        {/* <label className="my-form__label">Request Parameter Name Field 3:</label> */}
                        <input
                            className="my-form__input-text"
                            type="text"
                            name="RequestParameterNameField3"
                            value={formData.RequestParameterNameField3}
                            onChange={handleChange}
                            placeholder='Parameter Name'
                        />
                    </div>

                    <div className="my-form__group">
                        {/* <label className="my-form__label">Request Parameter Value Field 3:</label> */}
                        <input
                            className="my-form__input-text"
                            type="text"
                            name="RequestParameterValueField3"
                            value={formData.RequestParameterValueField3}
                            onChange={handleChange}
                            placeholder='Parameter Value'
                        />
                    </div>
                </div>

                {apiDropdown ? (<div className="my-form__button-container">
                    <button className="my-form__submit-btn" onClick={handleUpdate}>Update</button>
                </div>) : <div className="my-form__button-container">
                    <button className="my-form__submit-btn" type="submit">Submit</button>
                </div>}


            </form>
        </div>
    );
};

export default MyForm;
