// src/ImageUpload.js
import React, { useState } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import "./Imageupload.css";

const ImageUpload = ({name ,endpoint}) => {
  const [Name, setName] = useState("");
  const [imgdata, setImgdata] = useState(null);

  const handleNameChange = (e) => {
    // console.log(e.target.value)
    setName(e.target.value);
  };

  const handleImageChange = (e) => {
    // console.log(e.target.files[0])
    const files = Array.from(e.target.files)
    console.log(files)
    // setImgdata(e.target.files[0]);
    setImgdata(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Name", Name);
    // formData.append("Image", imgdata);  //single upload

    imgdata.forEach((file, index) => {
      formData.append(`Image`, file); // Using the same key for multiple files
    });
  

    console.log(formData);

    try {
      const response = await axios.post(
        endpoint, // /upload for single /uploads for multiple
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("Image uploaded successfully!");
      console.log(response.data);
      setName(" ");
      setImgdata(" ");
      document.getElementById('image').value = '';
      window.location.reload();
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Error uploading image");
    }
  };

  return (
    <div className="container">
      <form className="upload-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">{name}</label>
          <input
            type="text"
            id="name"
            value={Name}
            onChange={handleNameChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">Image</label>
          <input
            type="file"
            id="image"
            onChange={handleImageChange}
            accept="image/*"
            multiple
            required
          />
        </div>
        <button type="submit" className="submit-button">
          Upload
          <Icon icon="solar:upload-outline" />
        </button>
      </form>
    </div>
  );
};

export default ImageUpload;
