import React, { useEffect, useRef, useState } from 'react';
import { Icon } from "@iconify/react/dist/iconify.js";
import "../../css/wtpstyle.css";
import WTPSideBar from '../WhatsappCampaigns/WTPSideBar';
const WTPBusinessAccount = () => {

    const data = [
        { id: 1, description: 'WhatsApp Messages Payment (direct to Facebook)', link: 'https://www.facebook.com' },
        { id: 2, description: 'WhatsApp Business Manager', link: 'https://www.facebook.com/business' },
        { id: 3, description: 'Create WhatsApp Template', link: 'https://www.facebook.com/whatsapp' },
        { id: 4, description: 'Green Tick Tutorial', link: 'https://www.facebook.com/whatsapp' },
        { id: 5, description: 'WhatsApp Direct Support', link: 'https://www.facebook.com/whatsapp' },
    ];
    return (
        <div className="Flex-Head-Right-Compaigns">
            <div>
                <WTPSideBar />
            </div>
            <div className="Right-Campaigns-Body">
                <div className="Campaigns_header">
                    <div className="Campaigns_header">
                        <h3>WhatsApp Business Account Details </h3>
                    </div>
                    <div className="Campaigns_headerRight">
                        <button className="Campaigns_headerButton">
                            <Icon icon="arcticons:goodwy-contacts" />
                        </button>
                    </div>
                </div>
                <div className="Campaigns_body_Bg">
                    <div className="waba-status-content">Important WhatsApp and Facebook Panel Links</div>
                    <div className="Campaigns-form-content">
                        <div>
                            <table className="Table-Body-content">
                                <thead className="Table-Campaigns-color">
                                    <tr className="Table-Bg-color">
                                        <th className="th-name-camp">Description</th>
                                        <th className="th-name-camp">Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(item => (
                                        <tr key={item.id} className="Table-Bg-color">
                                            <td className="td-name-campaigns">{item.description}</td>
                                            <td className="td-name-campaigns">
                                                <a href={item.link} target="_blank" rel="noopener noreferrer" className='clickhere-color'>
                                                    Click Here
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WTPBusinessAccount
