import React, { useMemo, useRef, useState, useEffect } from "react";

const ModalQueryMaster = (props) => {
    return (

        <div>
            <div className="Model-Query-Content">
                <div className="Content-padding-Campaigns">
                    <div className="Flex-Input-Campaigns">
                        <div className='Input-Box-Compaigns Query-width-name'>
                            <label className="Campaigns-lbl">Title</label>
                            <input className="" type="text" name="queryName" placeholder="Title" onChange={props.handleChange} value={props.formData.queryName} />
                        </div>
                    </div>
                </div>
                <div className='Text-Query-width'>
                    <div>
                        <textarea className="Input-textarea-Box" type="text" name='query' placeholder='Query' onChange={props.handleChange} value={props.formData.query} />
                    </div>
                </div>
                <div className="view-all-container">
                    <button className="view-all-button" onClick={props.onClick}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalQueryMaster;
