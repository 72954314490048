import React, { useContext, useEffect, useState } from "react";
// import "./newsandnotice.css";
import "../../css/style.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
// import Modal from "./Modal";
import Backend_Url from "../../config/config";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import MyContext from "../../context/Mycontext";

const SignUp = () => {
    //**************************************************** */

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [userid, setUserId] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [companyCode, setCompanyCode] = useState('');
    const { companyDetails } = useContext(MyContext)
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };


    const handleSignUp = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${Backend_Url}/users/v1/signup`, {
                companyCode,
                userid,
                email,
                password,
            });
            if (response) {
                setSuccess("Sign-up successful!");
                alert("User Created Successfully")
                // Redirect to login page or any other page
                setTimeout(() => {
                    navigate("/UserLogin");
                }, 1500);
            } else {
                setError(response.data.message || "Sign-up failed. Please try again.");
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
        }
    };

    const handleCompanyCodeChange = (e) => {
        setCompanyCode(e.target.value);
    };

    return (
        <div className="App">
            <div className="body-content">
                <div className="Flex-Login-User">
                    <div class="Login-container">
                        <div class="heading">Sign Up</div>
                        <div action="" class="form">
                            <select
                                required
                                className="input-Box-login"
                                value={companyCode}
                                name="companyCode"
                                id="companyCode"
                                onChange={handleCompanyCodeChange}
                            >
                                <option value="" disabled>Select Company</option>
                                {companyDetails && companyDetails.map((company) => (
                                    <option key={company.id} value={company.compCode}>
                                        {company.compName}
                                    </option>
                                ))}
                                {/* Add more options as needed */}
                            </select>
                            <input required="" className="input-Box-login" type="text" name="userid" id="userid" value={userid} onChange={(e) => setUserId(e.target.value)} placeholder="User ID" />
                            <input required="" className="input-Box-login" type="text" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email" />
                            <input required="" className="input-Box-login" type={passwordVisible ? 'text' : 'password'} name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                            <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                            </span>
                            <button class="login-button" type="submit" value="Sign Up" onClick={handleSignUp}>
                                Sign Up
                            </button>
                            {/* {error && <div className="error">{error}</div>}
                            {success && <div className="success">{success}</div>} */}
                            <span className="back-to-signin">
                                <Link to="/UserLogin">
                                    Back to Sign In
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
