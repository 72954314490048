import React, { useState } from "react";



const ModalAddCategory = ({ }) => {

    return (

        <div className="Header-Category-Content">
            <div className="Category-Left-Width">
                <div className="Flex-Input_General">
                    <div className="Input_Box-General">
                        <label className="inputName-lbl">Category/Sub Category</label>
                        <input className="Input-Fill-Box" type="text" name="ProjectName" placeholder="" />
                    </div>
                    <div className="Input_Box-General">
                        <label className="inputName-lbl">Email</label>
                        <input className="Input-Fill-Box" type="text" name="Email" placeholder="" />
                    </div>
                    <div className="Input_Box-General">
                        <label className="inputName-lbl">Phone</label>
                        <input className="Input-Fill-Box" type="text" name="Phone" placeholder="" />
                    </div>
                    <div className="Input_Box-General">
                        <label className="inputName-lbl">Address1</label>
                        <input className="Input-Fill-Box" type="text" name="Address1" placeholder="" />
                    </div>
                </div>
            </div>
            <div className="Category-Right-width">
                <div className="">
                    <div className="">
                        <label className="inputName-lbl"> Project Name</label>
                        <input className="Input-Fill-Box" type="text" name="ProjectName" placeholder="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAddCategory;
