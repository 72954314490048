import React, { useEffect, useRef, useState } from 'react';
import { Icon } from "@iconify/react/dist/iconify.js";
import "../../css/wtpstyle.css";
import { FaCheckCircle, FaFileAlt } from 'react-icons/fa';
import WTPSideBar from '../WhatsappCampaigns/WTPSideBar';
import ModelUsers from '../WhatsappCampaigns/ModelUsers';
import ModelAddContact from './ModelAddContact';
import ModelAddGroups from './ModelAddGroups';
import axios from 'axios';
import Backend_Url from '../../config/config';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import ModalImportContact from './ModalImportContact';
import Modal from '../modal';
import MemberModal from './MembersshowModal';


const WTPContacts = () => {

    const renderStatusIcon = (status) => {
        if (status === 'Delivered') {
            return <FaCheckCircle style={{ color: 'green', marginRight: '5px' }} />;
        } else if (status === 'Draft') {
            return <FaFileAlt style={{ color: 'orange', marginRight: '5px' }} />;
        }
        return null;
    };

    const [selectedRows, setSelectedRows] = useState(new Set());
    const [GruopnameCheck, setGruopNameCheck] = useState([])
    const [searchvalue, setsearchvalue] = useState("")
    const [cookie] = useCookies(["token"])
    const token = cookie.token;
    const [groupData, setGroupData] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [AllcontactwithGruop, setAllcontactwithgruop] = useState([])
    const [isOpen, setisOpen] = useState(false)
    const [gruopWa_id, setgruopWa_id] = useState(null)
    /******************************************************** */
    console.log(groupData);
    console.log("Api Data", apiData);
    const [showMenu, setShowMenu] = useState(null); // State to manage the visibility of the menu
    const menuRefAction = useRef(null); // Ref for the dropdown menu

    const handleexport = async () => {
        if (!token) {
            Swal.fire({
                icon: 'warning',
                title: 'No Token Found',
                text: 'Please log in to export contacts.',
            });
            return;
        }
        // debugger
        try {
            const response = await axios.get(`${Backend_Url}/contacts/export-contacts`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                responseType: 'blob' // Important for handling binary data
            });

            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

            // Create a link element and trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'contacts.xlsx'); // Set the default file name
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            Swal.fire({
                icon: 'success',
                title: 'Download Successful',
                text: 'Contacts have been exported successfully.',
            });

        } catch (error) {
            console.error(error);

            Swal.fire({
                icon: 'error',
                title: 'Export Failed',
                text: 'There was an error exporting the contacts. Please try again later.',
            });
        }
    };

    const OpenMembersModal = (wa_id) => {
        setgruopWa_id(wa_id)
        setisOpen(true)
    }

    const handleIconClick = (id) => {
        // debugger
        console.log("Icon clicked", id);
        setShowMenu((prev) => (prev === id ? null : id)); // Toggle menu visibility
    };

    const handleEdit = (item, type) => {
        if (type === 1) {
            setEditableItem(item)
            setModalAddContact(true);
            console.log(`Edit campaign ${item}`);
            setShowMenu(null);
        }
        if (type === 2) {
            setEditableItem(item)
            setModalAddGroup(true);
            console.log(`Edit campaign ${item}`);
            setShowMenu(null);
        }
    };

    const handleDelete = (id) => {
        // alert(id)
        deleteContact(id)
        console.log(`Delete campaign ${id}`);
        setShowMenu(null);
    };
    const handleGroupDelete = (id) => {
        deleteGroup(id)
    }

    const handleClickOutsideAction = (event) => {
        if (menuRefAction.current && !menuRefAction.current.contains(event.target)) {
            setShowMenu(null);
        }
    };


    const deleteGroup = async (id) => {
        debugger
        if (!token) {
            console.error('No token found');
            return;
        }
        const result = await Swal.fire({
            title: 'Are you delete this Group sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isDismissed) {
            return;
        }

        try {

            // -------------delete gruop-------------
            const response = await axios.delete(`${Backend_Url}/contactgroups/delete/contactgruop/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                fetchData()
                fetchGrpData()
                Swal.fire(
                    'Deleted!',
                    'Group has been deleted.',
                    'success'
                );
            }
        } catch (error) {
            console.log(error)
            Swal.fire(
                'Error!',
                'There was a problem deleting the Group.',
                'error'
            );
        }
    }

    const deleteContact = async (id) => {
        if (!token) {
            console.error('No token found');
            return;
        }
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isDismissed) {
            return;
        }

        try {
            const response = await axios.delete(`${Backend_Url}/contacts/contacts/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            if (response.status === 200) {
                fetchData()
                fetchGrpData()
                Swal.fire(
                    'Deleted!',
                    'Your contact has been deleted.',
                    'success'
                );
            }
        } catch (error) {
            Swal.fire(
                'Error!',
                'There was a problem deleting the contact.',
                'error'
            );
        }
    }

    useEffect(() => {
        // document.addEventListener('mousedown', handleClickOutsideAction);

        // return () => {
        //     document.removeEventListener('mousedown', handleClickOutsideAction);
        // };
    }, []);



    /********************************************** */

    const [showMenuUser, setShowMenuUser] = useState(false); // State to manage dropdown visibility
    const menuRef = useRef(null); // Ref for the dropdown menu

    // Toggle dropdown visibility
    const handleButtonClick = () => {
        setShowMenuUser(prev => !prev);
    };

    // Handle clicks outside of the menu to close it
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenuUser(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const fetchData = async (page = 1, limit = 50) => {
        if (!token) {
            console.error('No token found');
            return;
        }
        debugger
        try {
            const response = await axios.get(`${Backend_Url}/contacts/getallwithPage?page=${page}&limit=${limit}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setApiData(response.data);
                setTotalPages(response.data.totalPages);
                setCurrentPage(response.data.currentPage);
            } else {
                console.error('Failed to fetch contacts', response.data);
            }
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    };
    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };


    // const data = [
    //     { id: 1, name: 'Campaign A', country: 'India', contacts: 9978912068, email: 'user@gmail.com', status: 'Subscribed' },
    //     { id: 2, name: 'Campaign B', country: 'India', contacts: 9978912068, email: 'user@gmail.com', status: 'Subscribed' },
    //     { id: 3, name: 'Campaign C', country: 'India', contacts: 9978912068, email: 'user@gmail.com', status: 'Subscribed' }
    // ];

    const fetchGrpData = async () => {
        try {
            // setLoading(true); // Start loading
            const response = await axios.get(`${Backend_Url}/contactgroups/getall`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setGroupData(response.data); // Set data to state
        } catch (error) {
            console.error('Failed to fetch data:', error);

        }
    };
    useEffect(() => {
        fetchGrpData();
    }, []);




    // const group = [
    //     { id: 1, name: 'Campaign A', date: '	23 Mar 2024', member: 190 },
    //     { id: 2, name: 'Campaign B', date: '	23 Mar 2024', member: 190 },
    //     { id: 3, name: 'Campaign C', date: '	23 Mar 2024', member: 190 }
    // ];


    /************************************************************* */

    const handleCheckboxChange = (id) => {
        setSelectedRows(prevSelectedRows => {
            const updatedSelectedRows = new Set(prevSelectedRows);
            if (updatedSelectedRows.has(id)) {
                updatedSelectedRows.delete(id);
            } else {
                updatedSelectedRows.add(id);
            }
            return updatedSelectedRows;
        });
    };

    const handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        setIsSelectAllChecked(isChecked);
        if (isChecked) {
            const allIds = new Set(apiData.contacts.map(item => item.wa_id));
            setSelectedRows(allIds);
        } else {
            setSelectedRows(new Set());
        }
    };

    /******************************************** */

    const [isModalImportContact, setisModalImportContact] = useState(false);
    const [isModalAddContact, setModalAddContact] = useState(false);
    const [editableItem, setEditableItem] = useState();

    const handleModalImport = () => {
        setisModalImportContact(true)
    }

    const showModalAddContact = () => {
        setModalAddContact(true);
        setActiveTab(0);
    };
    const hideModalAddContact = () => {
        setModalAddContact(false);
    };
    useEffect(() => {
        fetchData();
    }, [isModalAddContact]);
    /******************************************** */
    // useEffect(() => {
    //     alert(isModalImportContact)
    // }, [isModalImportContact]);
    const [isModalAddGroup, setModalAddGroup] = useState(false);

    const showModalAddGroup = () => {
        setModalAddGroup(true);
        setActiveTab(1);
    };
    const hideModalAddGroup = () => {
        setModalAddGroup(false);
    };

    /******************************************** */

    const [dropdownVisibleContact, setDropdownVisibleContact] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const toggleDropdownContact = () => {
        setDropdownVisibleContact(!dropdownVisibleContact);
    };

    const handleClickOutsideDrop = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            buttonRef.current &&
            !buttonRef.current.contains(event.target)
        ) {
            setDropdownVisibleContact(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideDrop);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideDrop);
        };
    }, []);

    /***************************************************************** */

    const [modalVisibleFilters, setModalVisibleFilters] = useState(false);
    const modalReffilters = useRef(null);
    const triggerRef = useRef(null);

    const toggleModalFilters = () => {
        setModalVisibleFilters(!modalVisibleFilters);
    };

    const handleClickOutsideFilters = (event) => {
        if (
            modalReffilters.current &&
            !modalReffilters.current.contains(event.target) &&
            triggerRef.current &&
            !triggerRef.current.contains(event.target)
        ) {
            setModalVisibleFilters(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideFilters);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideFilters);
        };
    }, []);

    /************************************ */

    // const [activeTab, setActiveTab] = useState(null);

    const [activeTab, setActiveTab] = useState(() => {
        // Retrieve the saved state from local storage or default to 0
        const savedTab = localStorage.getItem('activeTab');
        return savedTab !== null ? JSON.parse(savedTab) : 0;
    });

    const handleClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    useEffect(() => {
        // Save the state to local storage whenever it changes
        localStorage.setItem('activeTab', JSON.stringify(activeTab));
    }, [activeTab]);



    // ------------------------------delete all------------------------------
    const handleDeleteAll = async (data) => {

        if (!token) {
            console.error('No token found');
            return;
        }
        debugger
        const result = await Swal.fire({
            title: 'Are you sure delete all contact?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isDismissed) {
            return
        }

        if (selectedRows.size == 0) {
            Swal.fire(
                'No contact selected!',
                'Please select contact to delete.',
                'error'
            );
            return
        }

        try {
            console.log(selectedRows)
            const setArray = Array.from(selectedRows)
            for (let i = 0; i < selectedRows.size; i++) {
                debugger
                console.log(setArray[i])
                const deleteitem = data.find((item) => item.wa_id === setArray[i])
                const response = await axios.delete(`${Backend_Url}/contacts/contacts/${deleteitem.wa_id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });

                if (i == selectedRows.size - 1 && response.status == 200) {
                    Swal.fire(
                        'Deleted!',
                        'Your contact has been deleted.',
                        'success'
                    );
                }
            }

            fetchData()
            fetchGrpData()

        } catch (error) {
            console.log(error)
        }
    }
    // -----------------------------handle gruopname------------------------

    const HandleGruopName = async (wa_id) => {
        debugger
        try {
            const response = await axios.get(`${Backend_Url}/contactgroups/getGruopByContact/${wa_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            // Wait for all promises to resolve
            const result = await response.data.data
            console.log(result)
            return result;
        } catch (error) {
            console.log(error)
        }

    }


    const [allgruop, setallgruop] = useState([])

    const fetchgruop = async () => {
        for (let i = 0; i < apiData?.contacts?.length; i++) {
            const result = await HandleGruopName(apiData.contacts[i].wa_id)
            setallgruop((prev) => [...prev, result])
        }
    }
    useEffect(() => { fetchgruop() }, [apiData])

    console.log(allgruop);

    return (
        <div className="Flex-Head-Right-Compaigns">
            <div>
                <WTPSideBar />
            </div>
            <div className="Right-Campaigns-Body">
                <div className="Campaigns_header">
                    <div className="Campaigns_header">
                        <h3>Contact Management</h3>
                    </div>
                    <div className="Campaigns_headerRight">
                        <button className="Campaigns_headerButton" onClick={handleButtonClick}>
                            <Icon icon="arcticons:goodwy-contacts" />
                            {showMenuUser && (
                                <div ref={menuRef}>
                                    <ModelUsers />
                                </div>
                            )}
                        </button>
                    </div>
                </div>
                <div className="Campaigns_body_Bg">
                    <div className="Create-Compaigns-container">
                        <div>
                            <button className="export-all-button" onClick={handleexport}>
                                <Icon icon="clarity:export-line" /> Export Contacts Into Excel
                            </button>
                        </div>
                        <div>
                            <button className="export-all-button" onClick={handleModalImport} >
                                <Icon icon="clarity:export-line" /> Import Contacts From Excel
                            </button>
                        </div>
                        <div className="action-buttons">
                            {activeTab === 0 && (
                                <button className="view-all-button" onClick={showModalAddContact}>
                                    <Icon icon="material-symbols:add" /> Add Contact
                                </button>
                            )}
                            {activeTab === 1 && (
                                <button className="view-all-button" onClick={showModalAddGroup}>
                                    <Icon icon="material-symbols:add" /> Add Group
                                </button>
                            )}
                        </div>
                    </div>
                    {isModalAddContact && (
                        <div className="modal-Category">
                            <div className="modal-content modal-campaigns-width">
                                <div className="modal-title">
                                    <h3 className="modal-heading-top">Add Contact</h3>
                                    <span className="close" onClick={hideModalAddContact}>&times;</span>
                                </div>
                                <div>
                                    <ModelAddContact editableItem={editableItem} setModalAddContact={setModalAddContact} fetchContactData={fetchData} fetchGrpData={fetchGrpData} />
                                </div>
                            </div>
                        </div>
                    )}

                    {isModalImportContact && (
                        <div className="modal-Category">
                            <div className="modal-content modal-campaigns-width">
                                <div className="modal-title">
                                    <h3 className="modal-heading-top">Import Contact</h3>
                                    <span className="close" onClick={() => setisModalImportContact(false)}>&times;</span>
                                </div>
                                <div>
                                    <ModalImportContact fetchContactData={fetchData} fetchGrpData={fetchGrpData} />
                                </div>
                            </div>
                        </div>
                    )}

                    {isModalAddGroup && (
                        <div className="modal-Category">
                            <div className="modal-content modal-campaigns-width">
                                <div className="modal-title">
                                    <h3 className="modal-heading-top">Group</h3>
                                    <span className="close" onClick={hideModalAddGroup}>&times;</span>
                                </div>
                                <div>
                                    <ModelAddGroups editableItem={editableItem} fetchGrpData={fetchGrpData} />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="Contacts-Groups-content">
                        <div
                            className={`Groups-Contacts-width ${activeTab === 0 ? 'active' : ''}`}
                            onClick={() => handleClick(0)}
                        >
                            All Contacts
                        </div>
                        <div
                            className={`Groups-Contacts-width ${activeTab === 1 ? 'active' : ''}`}
                            onClick={() => handleClick(1)}
                        >
                            Groups
                        </div>
                    </div>
                    {activeTab === 0 && (
                        <div>
                            <div className="Campaigns-form-content">
                                <div className='contact-subscribed'>28349 Contacts. 28313 Subscribed Contacts. 36 Unsubscribed.</div>
                                <div className="Content-padding-Campaigns Content-position-contact">
                                    <div className="Flex-Input-Campaigns">
                                        <div className='Input-Box-Compaigns'>
                                            <input className="search-input-contact" type="text" name="" placeholder="Search" />
                                        </div>
                                        {/* <div>
                                    <Icon icon="material-symbols:search" />
                                </div> */}

                                        <div className='Input-Box-Compaigns'>
                                            <button className="Contact-Type-button" onClick={toggleDropdownContact} ref={buttonRef}>
                                                Contact Type
                                            </button>
                                            {dropdownVisibleContact && (
                                                <div className="Dropdown-menu-Contact" ref={dropdownRef}>
                                                    <div className="Dropdown-item-contact">All</div>
                                                    <div className="Dropdown-item-contact">Subscribed</div>
                                                    <div className="Dropdown-item-contact">Unsubscribed</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='Input-Box-Compaigns'>
                                            <div className='Icon-flex-add' onClick={toggleModalFilters}>
                                                <select className="Query-select-Box" type="text" name="" placeholder="Filters" >
                                                    <option>Filters</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {modalVisibleFilters && (
                                    <div className="Content-padding-Campaigns" ref={modalReffilters}>
                                        <div className="Flex-Input-Campaigns">
                                            <div className='Input-Box-Compaigns'>
                                                <label className="Campaigns-lbl">Created On</label>
                                                <div className='Icon-flex-add'>
                                                    <select className="Query-select-Box" type="text" name="" placeholder="" >
                                                        <option>--select--</option>
                                                        <option>Blank</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='Input-Box-Compaigns'>
                                                <label className="Campaigns-lbl">Filter By Group</label>
                                                <div className='Icon-flex-add'>
                                                    <select className="Query-select-Box" type="text" name="" placeholder="" >
                                                        <option>--select--</option>
                                                        <option>Blank</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='Input-Box-Compaigns'>
                                                <label className="Campaigns-lbl">Country</label>
                                                <div className='Icon-flex-add'>
                                                    <select className="Query-select-Box" type="text" name="" placeholder="" >
                                                        <option>--select--</option>
                                                        <option>Blank</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="Campaigns-form-content">
                                <div>
                                    <table className="Table-Body-content">
                                        <thead className="Table-Campaigns-color">
                                            <tr className="Table-Bg-color">
                                                <th className="th-name-camp">
                                                    <input
                                                        type="checkbox"
                                                        className="header-checkbox"
                                                        checked={isSelectAllChecked}
                                                        onChange={handleSelectAllChange}
                                                    />
                                                    <button onClick={() => handleDeleteAll(apiData.contacts)} className={`dlt-btn`}>
                                                        <span className={`delete-button`}>
                                                            <Icon icon="fluent:delete-12-filled" />
                                                        </span>
                                                    </button>
                                                </th>
                                                <th className="th-name-camp">Full Name</th>
                                                <th className="th-name-camp">Country</th>
                                                <th className="th-name-camp">Mobile Number</th>
                                                <th className="th-name-camp">Email</th>
                                                <th className="th-name-camp">Group</th>
                                                <th className="th-name-camp">Status</th>
                                                <th className="th-name-camp"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {apiData && apiData.contacts ? (
                                                apiData.contacts.map(item => (
                                                    <tr key={item.wa_id} className="Table-Bg-color">
                                                        <td className="td-name-campaigns">
                                                            <input
                                                                className='Checkbox-table-row'
                                                                type="checkbox"
                                                                checked={selectedRows.has(item.wa_id)}
                                                                onChange={() => handleCheckboxChange(item.wa_id)}
                                                            />
                                                        </td>
                                                        <td className="td-name-campaigns">{item.Profile}</td>
                                                        <td className="td-name-campaigns">{item.country || 'India'} </td>
                                                        <td className="td-name-campaigns"> {item.wa_id} </td>
                                                        <td className="td-name-campaigns">{item.Email !== null ? item.Email : "Nil"}</td>
                                                        <td className={`td-name-campaigns gruop-span`}>{allgruop.find((includesitem) => includesitem.wa_id == item.wa_id) ? <span className={`span-cross`}>In Group</span> : <span className={`span-cross`}><Icon icon="healthicons:no" /></span>}</td>
                                                        <td className="td-name-campaigns">
                                                            {renderStatusIcon(item.status)} {item.status}
                                                            ------
                                                        </td>
                                                        <td className="td-name-campaigns">
                                                            <div className="action-icon-container" ref={menuRefAction} >
                                                                <Icon
                                                                    icon="bi:three-dots-vertical"
                                                                    onClick={() => handleIconClick(item.wa_id)}
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                                {showMenu === item.wa_id && (
                                                                    <div className="dropdown-menu-action">
                                                                        <div>
                                                                            <button onClick={() => handleEdit(item, 1)}>Edit</button>
                                                                        </div>
                                                                        <div>
                                                                            <button>Unsubscribe</button>
                                                                        </div>
                                                                        <div>
                                                                            <button onClick={() => handleDelete(item.wa_id)}>Delete</button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="7">Loading...</td>
                                                </tr>
                                            )}
                                        </tbody>

                                    </table>
                                </div>
                                <div className="pagination-controls">
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        <Icon icon="ooui:arrow-next-rtl" width="12" height="12" style={{ color: "black" }} />

                                    </button>
                                    <span>Page {currentPage} of {totalPages}</span>
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        <Icon icon="ooui:arrow-next-ltr" width="12" height="12" style={{ color: "black" }} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 1 && (
                        // <div>
                        //     <ModelGroups />
                        // </div>
                        <div className="Campaigns-form-content">
                            <div>
                                <table className="Table-Body-content">
                                    <thead className="Table-Campaigns-color">
                                        <tr className="Table-Bg-color">
                                            <th className="th-name-camp">Group Name</th>
                                            <th className="th-name-camp">Created Date</th>
                                            <th className="th-name-camp">Member</th>
                                            <th className="th-name-camp">Details</th>
                                            <th className="th-name-camp"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {groupData.map(item => (
                                            <tr key={item.wa_id} className="Table-Bg-color">
                                                <td className="td-name-campaigns">{item.Name}</td>
                                                <td className="td-name-campaigns">{item.CreatedOn}</td>
                                                <td className="td-name-campaigns"> {item.memberCount}
                                                </td>
                                                <td className="td-name-campaigns">
                                                    <p onClick={() => OpenMembersModal(item.wa_id)} className={`view-member`}>
                                                        <Icon icon="fluent:people-48-filled" style={{ color: "#528052" }} />
                                                        Members
                                                    </p>

                                                </td>
                                                <td className="td-name-campaigns">
                                                    <div className="action-icon-container" ref={menuRefAction}>
                                                        <Icon
                                                            icon="bi:three-dots-vertical"
                                                            onClick={() => handleIconClick(item.wa_id)}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                        {showMenu === item.wa_id && (
                                                            <div className="dropdown-menu-action">

                                                                <button onClick={() => handleEdit(item, 2)}>Edit</button>


                                                                <button onClick={() => handleGroupDelete(item.wa_id)}>Delete</button>

                                                                <div onClick={() => setShowMenu(null)} className={`dropdown-menu-action-close`}></div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    )}

                </div>
                {
                    isOpen && <MemberModal isOpen={isOpen} onClose={setisOpen} wa_id={gruopWa_id} />
                }

            </div>
        </div>
    )
}

export default WTPContacts
