import React, { useEffect, useState, useRef } from "react";
import "../../css/style.css";
import "../../css/whatsapp.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
// import Modal from "./Modal";



const ModalAttachMenu = () => {
    //**************************************************** */

    return (

        <div className="attachment-menu">
            <div className="attachment-menu-item">
                <Icon icon="ic:baseline-insert-drive-file" />
                <span>Document</span>
            </div>
            <div className="attachment-menu-item">
                <Icon icon="ic:baseline-photo" />
                <span>Photos & Videos</span>
            </div>
            <div className="attachment-menu-item">
                <Icon icon="ic:baseline-camera-alt" />
                <span>Camera</span>
            </div>
            <div className="attachment-menu-item">
                <Icon icon="ic:baseline-contacts" />
                <span>Contact</span>
            </div>
            <div className="attachment-menu-item">
                <Icon icon="ic:baseline-poll" />
                <span>Poll</span>
            </div>
            <div className="attachment-menu-item">
                <Icon icon="ic:baseline-sticky-note-2" />
                <span>New Sticker</span>
            </div>
            <div className="attachment-menu-item">
                <Icon icon="ic:baseline-view-list" />
                <span>Catalog</span>
            </div>
            <div className="attachment-menu-item">
                <Icon icon="ic:baseline-quickreply" />
                <span>Quick Replies</span>
            </div>
        </div>

    );
};

export default ModalAttachMenu;
