import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Backend_Url from '../../config/config'




const Whatsapp = () => {


    const [apiData, setApiData] = useState()
    const [userApiData, setUserApiData] = useState()
    console.log(userApiData)

    const fetchdata = async () => {
        // debugger
        try {
            const response = await axios.get(`${Backend_Url}/whatsapp/request/getall`)
            if (response) {
                // alert("fetched")
                setApiData(response.data)
            }
        } catch (error) {
            console.error("Unable To fetch Data", error)
        }
    }

    const fetchdata1 = async () => {
        // debugger
        try {
            const response = await axios.get(`${Backend_Url}/whatsapp/getallURL/all`)
            if (response) {
                // alert("fetched")
                setUserApiData(response.users)
            }
        } catch (error) {
            console.error("Unable To fetch Data", error)
        }
    }

    useEffect(() => {
        fetchdata()
        fetchdata1()
    }, [])

    return (
        <div>

            <h1 style={{ textAlign: "center" }}>WA PANEL</h1>
            <div>
                <div className="form-content">
                    {apiData ? (
                        <table>
                            <thead>
                                <th>Id</th>
                                <th>Header</th>
                                <th>Body</th>
                                <th>Query String</th>
                                <th>Date/Time<div className="date-format">(yyyy-mm-dd)</div> </th>
                                {/* <th>Status</th>
                                <th>Type</th> */}
                            </thead>
                            <tbody>
                                {apiData.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div style={{ width: "1000px", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                {item.header}
                                            </div>
                                        </td>
                                        <td>{item.body}</td>
                                        <td>{item.querystring}</td>
                                        <td>{item.dt}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        "No data"
                    )}
                </div>

            </div>
        </div>
    )
}

export default Whatsapp
