import React, { useContext, useEffect, useState } from "react";
// import "./newsandnotice.css";
import "../../css/style.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
// import Modal from "./Modal";
import Backend_Url from "../../config/config";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import MyContext from "../../context/Mycontext";
import Swal from "sweetalert2";

const CreateCompany = () => {
    //**************************************************** */

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [compName, setcompName] = useState("");
    const [emailId, setemailId] = useState("");
    const [DbName, setDbName] = useState("");
    // const [companyCode, setCompanyCode] = useState('');
    const navigate = useNavigate();

    console.log({
        "compName": compName,
        "emailId": emailId,
        "DbName": DbName
    });

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const { fetchCompData } = useContext(MyContext)


    const handleSignUp = async (e) => {
        e.preventDefault();
        // debugger
        try {
            const response = await axios.post(`${Backend_Url}/companydetails/create`, {
                compName,
                emailId,
                DbName,
            });
            if (response && response.data) {
                const companyCode = response.data.companyCode; // Assuming companyCode is in the response

                // Show success alert with company code
                Swal.fire({
                    title: 'Company Created Successfully',
                    html: `Your company code is: <strong>${companyCode}</strong>. <br />Note it down.`,
                    icon: 'success',
                    showCancelButton: true,
                    confirmButtonText: 'Copy Code',
                    cancelButtonText: 'Close',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigator.clipboard.writeText(companyCode);
                        Swal.fire('Copied!', 'Company code has been copied to clipboard.', 'success');
                    }
                });

                // Optional: Redirect to login page or any other page
                // setTimeout(() => {
                //     navigate("/UserLogin");
                // }, 1500);
                // fetchCompData();
            } else {

                Swal.fire('Error', 'Sign-up failed. Please try again.', 'error');
            }
        } catch (error) {
            // setError("An error occurred. Please try again.");
            console.error("Unable To Create")
        }
    };

    // const handleCompanyCodeChange = (e) => {
    //     setCompanyCode(e.target.value);
    // };

    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters[randomIndex];
        }
        return result;
    };
    const generateDbName = (name) => {
        const randomString = generateRandomString(6); // Random string of 6 characters
        return `${name.replace(/\s+/g, '_').toLowerCase()}_${randomString}_db`;
        // return name.replace(/\s+/g, '_').toLowerCase() + '_db';
    };

    // Effect to update DbName whenever compName changes
    useEffect(() => {
        if (compName) {
            setDbName(generateDbName(compName));
        }
    }, [compName]);

    return (
        <div className="App">
            <div className="body-content">
                <div className="Flex-Login-User">
                    <div class="Login-container">
                        <div class="heading">Create Company</div>
                        <div action="" class="form">
                            <input required="" className="input-Box-login" type="text" name="compName" id="compName" value={compName} onChange={(e) => setcompName(e.target.value)} placeholder="Company Name" />
                            <input required="" className="input-Box-login" type="text" name="emailId" id="emailId" value={emailId} onChange={(e) => setemailId(e.target.value)} placeholder="emailId" />
                            {/* <input required="" className="input-Box-login" type="text" name="DbName" id="DbName" value={DbName} onChange={(e) => setDbName(e.target.value)} placeholder="DbName" /> */}
                            {/* <input required="" className="input-Box-login" type={passwordVisible ? 'text' : 'password'} name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" /> */}
                            {/* <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                            </span> */}
                            <button class="login-button" type="submit" value="Sign Up" onClick={handleSignUp}>
                                Create
                            </button>
                            {/* {error && <div className="error">{error}</div>}
                            {success && <div className="success">{success}</div>} */}
                            <span className="back-to-signin">
                                <Link to="/UserLogin">
                                    Back to Sign In
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCompany;
