import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
// import "./newsandnotice.css";
import "../../css/style.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
// import Modal from "./Modal";
import Backend_Url from "../../config/config";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    //**************************************************** */

    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();


    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setMessage("");
        setError("");
        try {
            const response = await axios.post(`${Backend_Url}/users/forgot-password`, { email });
            if (response.data.message) {
                setMessage(response.data.message);
                    setTimeout(() => {
                     navigate("/ResetPassword")   
                    }, 500);
             
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError("An error occurred. Please try again.");
            }
        }
    };

    return (
        <div className="App">
            <div className="body-content">
                <div className="Flex-Login-User">
                    <div class="Login-container">
                        <div class="heading">Forgot Password</div>
                        <div action="" class="form">
                            <input required="" className="input-Box-login" type="text" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                            {/* <input required="" className="input-Box-login" type="text" name="password" id="password" placeholder="Token" /> */}
                            <button class="login-button" type="submit" value="" onClick={handleForgotPassword}>
                                Send Email
                            </button>
                            {message && <div className="success">{message}</div>}
                            {error && <div className="error">{error}</div>}
                            <span className="back-to-signin">
                                <Link to="/UserLogin">
                                    Back to Sign In
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
