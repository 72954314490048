// src/components/Modal.js
import React, { useEffect, useState } from 'react';
import '../../css/wtpstyle.css'; // For basic styling
import axios from 'axios';
import Backend_Url from '../../config/config';
import { useCookies } from 'react-cookie';

const MemberModal = ({ isOpen, onClose, wa_id }) => {
    const [cookies, setcookies, removecookies] = useCookies(['token'])
    const [result, setResult] = useState([])
    useEffect(() => { fetchdata() }, [])


    const fetchdata = async () => {
        try {
            const response = await axios.get(`${Backend_Url}/contactgroups/${wa_id}/contacts`,
                {
                    headers: {
                        Authorization: `Bearer ${cookies?.token}`,
                    },
                }
            )

            const result = await response.data
            setResult(result.contacts)
        } catch (error) {
            console.log(error)
        }
    }

    if (!isOpen) return null;

    console.log(result, wa_id)
    return (
        <div className="modal-overlay-members">
            <div className="modal-content-members">
                <button className="close-button-members" onClick={() => onClose(false)}>X</button>
                <h2>Member List</h2>
                <ul style={{ listStyle: "none", padding: "0" }}>
                    {result.map((member, index) => (
                        member.Profile != ' ' ? (
                            <li
                                style={{ padding: "5px 0", borderBottom: "1px solid", borderBottomColor: "gray" }}
                                key={index}
                            >
                                {member.Profile}
                            </li>
                        ) : member.wa_id
                    ))}
                </ul>
            </div>
        </div>
    );
};


export default MemberModal;