import React, { useEffect, useState, useRef } from "react";
import "../../css/style.css";
import "../../css/whatsapp.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
// import Modal from "./Modal";



const WhatsappBtnMenu = () => {
    //**************************************************** */
    const [activeIcon, setActiveIcon] = useState(null);

    const handleIconClick = (iconName) => {
      setActiveIcon(iconName);
    };
    return (

        <div className="chat__footer_mobile ">
            <div className="footer-icon_mobile">
                {/* <Icon className="Icon-footer-wtp" icon="material-symbols:chat" /> */}
                <Icon
                    className={`Icon-footer-wtp ${activeIcon === 'chats' ? 'active' : ''}`}
                    icon="material-symbols:chat"
                    onClick={() => handleIconClick('chats')}
                />
                <span>Chats</span>
            </div>
            <div className="footer-icon_mobile">
                {/* <Icon className="Icon-footer-wtp" icon="f7:status" /> */}
                <Icon
                    className={`Icon-footer-wtp ${activeIcon === 'updates' ? 'active' : ''}`}
                    icon="f7:status"
                    onClick={() => handleIconClick('updates')}
                />
                <span>Updates</span>
            </div>
            <div className="footer-icon_mobile">
                {/* <Icon className="Icon-footer-wtp" icon="mdi:person-group" /> */}
                <Icon
                    className={`Icon-footer-wtp ${activeIcon === 'communities' ? 'active' : ''}`}
                    icon="mdi:person-group"
                    onClick={() => handleIconClick('communities')}
                />
                <span>Communities</span>
            </div>
            <div className="footer-icon_mobile">
                {/* <Icon className="Icon-footer-wtp" icon="material-symbols:call" /> */}
                <Icon
                    className={`Icon-footer-wtp ${activeIcon === 'calls' ? 'active' : ''}`}
                    icon="material-symbols:call"
                    onClick={() => handleIconClick('calls')}
                />
                <span>Calls</span>
            </div>
        </div>


    );
};

export default WhatsappBtnMenu;
